import AJV from 'ajv';

const ajv = new AJV({ allErrors: true, verbose: true });
const uncompressedFrameSizeSchema = {
    type: 'object',
    properties: {
        width: {
            type: 'integer',
            minimum: 0,
            maximum: 99999
        },
        height: {
            type: 'integer',
            minimum: 0,
            maximum: 99999
        },
        bitDepth: {
            type: 'number',
            minimum: 0,
            maximum: 99
        },
        playbackRate: {
            type: 'number',
            minimum: 0,
            maximum: 999
        },
        runTime: {
            type: 'number',
            minimum: 0,
            maximum: 9999
        }
    },
    required: ['width', 'height', 'bitDepth', 'playbackRate', 'runTime'],
    additionalProperties: false
};

export default ajv.compile(uncompressedFrameSizeSchema);
