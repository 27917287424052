import AJV from 'ajv';

const ajv = new AJV({ allErrors: true, verbose: true });
const luminanceSchema = {
    type: 'object',
    properties: {
        amount: {
            type: 'number',
            minimum: 0,
            maximum: 99999
        },
        conversionType: {
            type: 'number',
            minimum: 0.291863508,
            maximum: 4
        }
    },
    required: ['amount', 'conversionType'],
    additionalProperties: false
};

export default ajv.compile(luminanceSchema);
