import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Icon from './Icon';
import { Link } from 'react-router-dom';

const LinkWrapper = ({ link, children, ...props }) => {
    return link
        ? <Link {...props} to={link}>{children}</Link>
        : <button {...props}>{children}</button>;
};

const IconButton = props => {
    const [key, setKey] = useState(Math.random());

    return (
        <LinkWrapper
            key={key}
            link={props.link}
            className={`IconButton ${props.className} IconButton--${props.type} IconButton--${props.size}`}
            onClick={props.onClick ? (e) => {
                props.onClick(e);
                setKey(Math.random()); // force item to unfocus when clicked on
            } : null}
            disabled={props.disabled}
            data-value={props.dataValue}
        >
            <Icon
                name={props.name}
                className={`IconButton__icon ${props.className ? `${props.className}__icon` : ''}`}
            />
        </LinkWrapper>
    );
};

export default IconButton;

IconButton.propTypes = {
    // name of the icon
    name: PropTypes.string.isRequired,

    // className to pass down to tag
    className: PropTypes.string,

    // function to active on click
    onClick: PropTypes.func,

    // whether or not the button is disabled
    disabled: PropTypes.bool,

    // type of style the button icon has
    type: PropTypes.oneOf(['regular', 'light']),

    // size of the button
    size: PropTypes.oneOf(['small', 'medium', 'large']),

    // route to direct the icon button to
    link: PropTypes.string,

    // passing data attribute to button
    dataValue: PropTypes.string
};

LinkWrapper.propTypes = {
    link: PropTypes.string,
    children: PropTypes.object
};

IconButton.defaultProps = {
    className: '',
    disabled: false,
    type: 'regular',
    size: 'medium',
    dataValue: ''
};
