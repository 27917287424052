import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import qs from 'qs';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { Body, Heading } from '../modules/Typography';
import Spec from './Spec';

const Section = props => {
    const { slug, catSlug, subSlug } = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const count = props.counter;
    const query = qs.parse(location.search.slice(1));
    const [activeTab, setTab] = useState(props.section[0].data.attributes.shortName || props.section[0].data.attributes.name);
    const [spec, setSpec] = useState(null);
    useEffect(() => {
        setSpec(props.section.find(spec => (spec.data.attributes.name === activeTab || spec.data.attributes.shortName === activeTab)));
    }, [activeTab, slug, catSlug, subSlug, query.spec, query.tab]);

    useEffect(() => {
        let spec = props.section[0];
        if (query.spec) {
            const slugSpec = props.section.find(sect => sect.data.attributes.slug === query.spec);
            if (slugSpec) {
                spec = slugSpec;
            }
        }
        setSpec(spec);
        setTab(spec.data.attributes.shortName || spec.data.attributes.name);
    }, [props.section, query.spec]);

    const handleTab = (name) => {
        const tabSpec = props.section.find(spec => spec.data.attributes.name === name || spec.data.attributes.shortName === name);
        navigate(`/${catSlug}/${subSlug}/${slug}?tab=${props.sectionSlug}&spec=${tabSpec.data.attributes.slug}`);
    };

    return (
        <div className='Document__Requirements'>
            <div className='flex flex-start align-center wrap'>
                {props.section.length && props.section.map((spec, idx) => {
                    const name = spec ? spec.data.attributes.shortName || spec.data.attributes.name : '';
                    return (
                        <Fragment key={idx}>
                            {props.printed ? (
                                <>
                                    <div id={`toc_${count}`} />
                                    <Heading id={`toc_${count}.${idx + 1}`} className='Document__Requirements__name__print' block heavy number={5}>{count}.{idx + 1} {spec ? spec.data.attributes.name : ''}</Heading>
                                    {spec && <Spec count={count} printed spec={spec} sectionName={props.section[0].data.attributes.name} />}
                                </>
                            ) : (
                                <>
                                    {props.section.length > 1 &&
                                        <>
                                            {idx !== 0 ? <div className='Tab__divider' /> : ''}
                                            <Body
                                                className='button Document__SubTab'
                                                number={2}
                                                color={name === activeTab ? 'primary-light' : 'on-surface'}
                                                onClick={() => handleTab(name)}
                                            >
                                                {name}
                                            </Body>
                                        </>}
                                </>)}
                        </Fragment>
                    );
                })}
            </div>
            <Heading className='Document__Requirements__name' block heavy number={5}>{spec ? spec.data.attributes.name : ''}</Heading>
            {spec && <Spec spec={spec} sectionName={props.section[0].data.attributes.name} />}
        </div>
    );
};

export default Section;

Section.propTypes = {
    section: PropTypes.array,
    counter: PropTypes.number,
    printed: PropTypes.bool
};
