import { gql } from '@apollo/client';

export const GET_CATEGORY = gql`
    query categories ($slug: String) {
      categorieses (filters: {slug: {eq: $slug}}){
        data {
          id
          attributes {
            name
            slug
            description
            updates {
                date
                category
                description
            }
            subcategoryContainer {
              subcategory {
                data {
                  id
                  attributes {
                    name
                    slug
                    description
                    documentContainer {
                      document {
                        data {
                          id
                          attributes {
                            name
                            slug
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
`;

export const GET_CATEGORIES = gql`
  query categories {
    categorieses {
      data {
        id
        attributes {
          name
          slug
          description
        }
      }
    }
  }
`;
