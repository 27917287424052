function _height (width, aspectRatio) {
    const height = width / aspectRatio;
    return Number(height.toFixed(5)); // 5 decimals
}

const calculate = ({ width, aspectRatio }) => {
    const height = _height(width, aspectRatio);
    const heightRounded = Math.round(height);

    return {
        height, heightRounded
    };
};

export default calculate;
