import { gql } from '@apollo/client';

const GET_TIPS = gql`
  query tips($calculator: String) {
    calculatorses (filters: { name: {eq: $calculator} }) {
      data {
        id
        attributes {
          name
          tips {
            ...on ComponentTipsCalculatorTip {
              tip
            }
          }
        }
      }
  }}
`;

export { GET_TIPS };
