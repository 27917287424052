import React, { useState } from 'react';
import PropTypes from 'prop-types';

const Tooltip = (props) => {
    let timeout;
    const [active, setActive] = useState(false);

    const showTip = () => {
        timeout = setTimeout(() => {
            setActive(true);
        }, props.delay || 400);
    };

    const hideTip = () => {
        clearInterval(timeout);
        setActive(false);
    };

    return (
        <div
            className={`${props.printSection ? 'Document__ToolTip' : 'Tooltip-Wrapper'}`}
            onMouseEnter={showTip}
            onMouseLeave={hideTip}
        >
            {props.children}
            {active && (
                <div className={`Tooltip-Tip ${props.direction || 'top'}`}>
                    {props.content}
                </div>
            )}
        </div>
    );
};
Tooltip.propTypes = {
    delay: PropTypes.bool,
    direction: PropTypes.string,
    content: PropTypes.string,
    printSection: PropTypes.bool
};

export default Tooltip;
