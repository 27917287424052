import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import SideNavList from './SideNavList';
import Accordion from '../modules/Accordion';
import { GET_NAV } from '../../queries';
import { WidthContext } from '../../contexts/WidthContextProvider';

const SideNav = ({ isMobileMenuOpen, handleMobileMenu }) => {
  const { isMobile } = useContext(WidthContext);
  const location = useLocation();
  const { data, loading } = useQuery(GET_NAV);
  const [activeItem, setActiveItem] = useState('');
  const [slug, setSlug] = useState('');

  useEffect(() => {
    if (location.pathname.length > 1) {
      const paths = location.pathname.split('/');
      const path = paths[3];
      setActiveItem(path);
      setSlug(path);
    }

    handleMobileMenu();
  }, [location.pathname]);

  let categories;
  if (loading) {
    categories = [];
  } else {
    categories = data
      ? data.sideNav.data.attributes.categoryContainer.map(
          (cont) => cont.categories,
        )
      : [];
  }

  const handleSelect = (id) => {
    setActiveItem(id);
  };

  const calculatorNav = [
    {
      id: 1,
      name: 'Aspect Ratio Calculators',
      documents: [
        {
          data: {
            id: 2,
            attributes: {
              name: 'Active Line',
              slug: 'active-line',
            },
          },
        },
        {
          data: {
            id: 3,
            attributes: {
              name: 'Active Area vs Aspect Ratio',
              slug: 'active-area-vs-aspect-ratio',
            },
          },
        },
        {
          data: {
            id: 4,
            attributes: {
              name: 'Active Area',
              slug: 'active-area',
            },
          },
        },
        {
          data: {
            id: 5,
            attributes: {
              name: 'Pillarboxing',
              slug: 'pillarboxing',
            },
          },
        },
      ],
      slug: 'aspect',
    },
    {
      id: 6,
      name: 'Frame Size Calculators',
      documents: [
        {
          data: {
            id: 5,
            attributes: {
              name: 'Uncompressed Frame Size',
              slug: 'uncompressed-frame-size',
            },
          },
        },
      ],
      slug: 'frame-size',
    },
    {
      id: 7,
      name: 'Luminance Calculator',
      documents: [
        {
          data: {
            id: 7,
            attributes: {
              name: 'Nits and Foot-Lamberts',
              slug: 'nits-foot-lamberts',
            },
          },
        },
      ],
      slug: 'luminance',
    },
  ];

  return (
    <div className={`SideNav ${isMobileMenuOpen ? 'open' : ''} `}>
      <div>
        {categories.map((category) => {
          return (
            <div
              key={category.data.attributes.slug}
              className="SideNav__Category">
              <Accordion
                slug={category.data.attributes.slug}
                title={category.data.attributes.name}>
                <SideNavList
                  className="SideNav__List"
                  items={category.data.attributes.subcategoryContainer
                    .filter((container) => container.subcategory)
                    .map((container) => ({
                      value: container.subcategory.data.id,
                      name: container.subcategory.data.attributes.name,
                      documents:
                        container.subcategory.data.attributes.documentContainer.map(
                          (container) => container.document,
                        ),
                      slug: container.subcategory.data.attributes.slug,
                    }))}
                  activeItem={activeItem}
                  onSelect={handleSelect}
                  slug={slug}
                  categorySlug={category.data.attributes.slug}
                />
              </Accordion>
            </div>
          );
        })}
        <div className="SideNav__Category">
          <Accordion slug="calculators" title="Calculators">
            <SideNavList
              className="SideNav__List"
              items={calculatorNav}
              activeItem={activeItem}
              onSelect={handleSelect}
              slug={slug}
              categorySlug="calculators"
            />
          </Accordion>
        </div>
        {isMobile && (
          <>
            <div className="SideNav__Category">
              <Accordion slug="glossary" title="Glossary" />
            </div>
            <div className="SideNav__Category">
              <Accordion slug="contact" title="Contact" />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

SideNav.propTypes = {
  isMobileMenuOpen: PropTypes.bool,
  handleMobileMenu: PropTypes.func,
};

export default SideNav;
