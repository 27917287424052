import React, { Fragment } from 'react';
import { Link, useLocation } from 'react-router-dom';
import List from '../modules/List';
import PropTypes from 'prop-types';
import Button from '../modules/Button';
import { Subtitle } from '../modules/Typography';

const SideNavList = ({ categorySlug, items, activeItem, className, onSelect, buttonText, handleButton, columns, disabled }) => {
    const location = useLocation();
    const paths = location.pathname.split('/');
    const catSlug = paths[1];
    const subcatSlug = paths.length > 2 ? paths[2] : '';

    return (
        <ul className={`List ${className}`}>
            {!!buttonText && !!handleButton && (<Button disabled={disabled} text={buttonText} onClick={handleButton} />)}
            {items.map((item, index) => {
                return (
                    <Fragment key={`${item.id}-${item.name}`}>
                        <Link to={`/${categorySlug}/${item.slug}/${item.documents[0].data.attributes.slug}`} key={`${item.id}-${item.name}`}>
                            <Subtitle number={2} className={subcatSlug === item.slug && catSlug === categorySlug ? 'active' : ''}>{item.name}</Subtitle>
                        </Link>
                        <List
                            className='SideNav__SubList'
                            items={item.documents.map(doc => {
                                return (
                                    {
                                        value: doc.data.id.toString(),
                                        name: doc.data.attributes.name,
                                        link: `/${categorySlug}/${item.slug}/${doc.data.attributes.slug}`
                                    }
                                );
                            })}
                            activeItem={activeItem && item.documents.find(doc => doc.data.attributes.slug === activeItem) && catSlug === categorySlug ? item.documents.find(doc => doc.data.attributes.slug === activeItem).data.id.toString() : ''}
                        />
                    </Fragment>
                );
            })}
        </ul>
    );
};

export default SideNavList;

SideNavList.propTypes = {
    // items that make up the list
    items: PropTypes.array.isRequired,

    // className to pass onto tags
    className: PropTypes.string,

    // function to handle list item selection
    onSelect: PropTypes.func,

    // optional button at the top of the list
    buttonText: PropTypes.string,
    handleButton: PropTypes.func,

    // number of columns for the list
    columns: PropTypes.number,

    // whether or not the button is disabled
    disabled: PropTypes.bool,
    // active elementside
    activeItem: PropTypes.string,
    categorySlug: PropTypes.string
};

SideNavList.defaultProps = {
    className: '',
    columns: 1,
    disabled: false
};
