import React, { useState, useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import TopNav from './TopNav';
import Footer from './Footer';
import SideNav from './SideNav';
import { WidthContext } from '../../contexts/WidthContextProvider';

const Layout = props => {
    const { documentWidth } = useContext(WidthContext);
    const [isSearchPanelOpen, setSearchPanelOpen] = useState(true);
    const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
    const { pathname } = useLocation();

    useEffect(() => {
        if (window.s) {
            window.s.pageName = pathname;
            window.s.t();
        }
    }, [pathname, window.s]);

    useEffect(() => {
        setSearchPanelOpen(true);
    }, [documentWidth]);

    useEffect(() => {
        setSearchPanelOpen(true);
        setMobileMenuOpen(true);
    }, [location.pathname]);

    return (
        <div className='layout'>
            <TopNav
                className='topNav'
                isSearchPanelOpen={isSearchPanelOpen}
                setSearchPanelOpen={setSearchPanelOpen}
                handleMobileMenu={() => setMobileMenuOpen(!isMobileMenuOpen)}
            />
            <SideNav
                className='SideNav'
                isMobileMenuOpen={isMobileMenuOpen}
                handleMobileMenu={() => setMobileMenuOpen(!isMobileMenuOpen)}
            />
            <div className={`content ${isSearchPanelOpen ? '' : 'display-none'}`}>
                {props.children}
            </div>
            <Footer className='Footer' />
        </div>
    );
};

Layout.propTypes = {
    children: PropTypes.object
};

Layout.defaultProps = {};

export default Layout;
