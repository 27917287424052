import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useMutation } from '@apollo/client';
import { Heading, Body } from '../modules/Typography';
import Paper from '../modules/Paper';
import { Col } from '../modules/Grid';
import people from '../../assets/people.png';
import TextField from '../modules/TextField';
import TextArea from '../modules/TextArea';
import Button from '../modules/Button';
import { REQUEST_INFO } from '../../queries';

const Contact = () => {
    const [sendRequest] = useMutation(REQUEST_INFO);
    const [formOpen, setFormOpen] = useState(true);
    const [message, setMessage] = useState('');
    const [subject, setSubject] = useState('');
    const [email, setEmail] = useState('');
    const [errors, setErrors] = useState([]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const handleSend = async (e) => {
        e.preventDefault();

        // Handle when no interaction has occurred with the form prior to pressing the submit button
        if (!runEmailRegex(document.getElementById('emailField').value)) {
            return;
        }

        const variables = {
            subject,
            message,
            email
        };
        if (!errors.includes('email')) {
            await sendRequest({
                variables
            });
            setFormOpen(false);
        }
    };

    const testEmail = (e) => {
        const { value } = e.target;
        runEmailRegex(value);
    };

    function runEmailRegex (value) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if (re.test(value)) {
            setErrors(errors.filter(error => error !== 'email'));
            return true;
        } else {
            setErrors([...errors, 'email']);
            return false;
        }
    }

    return (
        <>
            <Helmet><title>Disney Media Tech Specs | Contact</title></Helmet>
            <div className='Document'>
                <div className='Document__header'>
                    <Heading number={5} heavy block className='Document__title'>Questions</Heading>
                </div>
                <Paper className='Contact__paper' elevation={6}>
                    <Col className='Contact__wrapper'>
                        <Heading number={5} heavy className='Contact__title'>Contact Us</Heading>
                        <div>
                            <Body className=''>{formOpen ? 'For questions regarding specifications or site support.' : 'Thanks for contacting us, one of us will be in touch with you shortly.'} </Body>
                        </div>

                        <Col className='Contact__form'>
                            {formOpen &&
                            <form className='Contact__form__wrapper'>
                                <TextField
                                    className='Contact__form__input'
                                    placeholder='Subject'
                                    onChange={(e) => setSubject(e.target.value)}
                                />
                                <TextField
                                    id='emailField'
                                    className='Contact__form__input'
                                    placeholder='Your Email'
                                    value={email}
                                    onBlur={testEmail}
                                    onChange={(e) => setEmail(e.target.value)}
                                    error={errors.includes('email')}
                                    errorMessage='Please enter a valid email'

                                />
                                <TextArea
                                    placeholder='Message'
                                    contact
                                    onChange={(e) => setMessage(e.target.value)}
                                />
                                <Button
                                    type='primary'
                                    color='primary-light'
                                    className='Contact__form__button'
                                    onClick={(e) => handleSend(e)}
                                >Submit
                                </Button>
                            </form>}
                            <Col className='Contact__image'>
                                <img src={people} />
                            </Col>

                        </Col>

                    </Col>
                </Paper>

            </div>

        </>
    );
};

export default Contact;
