import React, { useContext, useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import { SEARCH } from '../../queries';
import { CategoriesContext } from '../../contexts/CategoriesContextProvider';
import ChipSet from '../modules/ChipSet';
import Paper from '../modules/Paper';
import { Subtitle, Heading } from '../modules/Typography';
import parseMarkdown from '../../utils/markdownParser';
import { LoadingIcon } from '../modules/Loading';

const ignoreCategories = ['Integ', 'Disney Streaming'];
const SearchResults = () => {
    const { query } = useParams();
    const { categories } = useContext(CategoriesContext);
    const { data, loading, error } = useQuery(SEARCH, {
        variables: { searchTerm: query.replace('-', ' ') }
    });
    const [selectedCategory, setSelectedCategory] = useState('All');
    const [searchResults, setSearchResults] = useState([]);
    const [documents, setDocuments] = useState([]);

    useEffect(() => {
        if (data) {
            const _documents = [];

            let i = 0;
            const len = data.documents.data.length;
            for (; i < len; i++) {
                const document = data.documents.data[i];
                const _subcategories = [];
                const jen = document.attributes.subcategories.data.length;
                for (let j = 0; j < jen; j++) {
                    const subcategories = document.attributes.subcategories.data[j];

                    if (
                        !subcategories.attributes.category ||
            !subcategories.attributes.category.data
                    ) {
                        continue;
                    }
                    if (
                        ignoreCategories.includes(
                            subcategories.attributes.category.data.attributes.name
                        )
                    ) {
                        continue;
                    }

                    _subcategories.push(subcategories);
                }

                if (_subcategories.length > 0) {
                    document.attributes.subcategories = _subcategories;
                    _documents.push(document);
                }
            }

            setSearchResults(_documents);
            setDocuments(_documents);
        }
    }, [data]);

    useEffect(() => {
        if (selectedCategory === 'All') {
            setDocuments(searchResults);
        } else {
            console.log;
            const filteredDocuments = searchResults.filter((document) => {
                return document.attributes.subcategories.find((subcategories) => {
                    return (
                        subcategories.attributes.category.data.attributes.name ===
            selectedCategory
                    );
                });
            });

            setDocuments(filteredDocuments);
        }
    }, [selectedCategory]);

    if (loading) return <LoadingIcon />;
    if (error) console.error({ error });

    return (
        <div className='Search'>
            <Heading number={5} heavy block className='Document__title'>
                Search Results
            </Heading>
            <Paper elevation={6} className='Search__Paper'>
                <div className='flex Search__Header'>
                    <div>
                        <Subtitle block number={3}>
                            Search for "{query.replace('-', ' ')}"
                        </Subtitle>
                        <Subtitle block number={2}>
                            {documents.length} result{documents.length !== 1 ? 's' : ''}
                        </Subtitle>
                    </div>
                    <ChipSet
                        chips={['All', ...categories.map((cat) => cat.attributes.name)]}
                        activeChip={selectedCategory}
                        handleClick={(chip) => setSelectedCategory(chip)}
                    />
                </div>
                <div className='Search__results__container'>
                    {documents &&
            documents.length !== 0 &&
            documents.map((doc, idx) => {
                const { slug, subcategories } = doc.attributes;
                const subcategory = subcategories[0];
                const catSlug =
                subcategory.attributes.category.data.attributes.slug;
                const subSlug = subcategory.attributes.slug;

                return (
                    <div className='Search__result' key={`result-${idx}`}>
                        <Link to={`/${catSlug}/${subSlug}/${slug}`}>
                            <Subtitle block number={3} color='primary'>
                                {idx + 1}. {doc.attributes.name}
                            </Subtitle>
                        </Link>
                        <Subtitle block number={2}>
                            {parseMarkdown(doc.attributes.description)}
                        </Subtitle>
                    </div>
                );
            })}
                </div>
            </Paper>
        </div>
    );
};

export default SearchResults;
