function _activeWidth (height, aspectRatio) {
    const activeWidth = height * aspectRatio;
    return Number(activeWidth).toFixed(4); // 4 decimals
}

function _pillarboxWidth (width, activeWidth) {
    const pillarboxWidth = (width - Math.round(activeWidth)) / 2;
    return pillarboxWidth;
}

const calculate = ({ width, height, aspectRatio }) => {
    const activeWidth = _activeWidth(height, aspectRatio);
    const activeWidthRounded = Math.round(activeWidth); // integer
    const pillarboxWidth = _pillarboxWidth(width, activeWidth); // integer

    return {
        activeWidth, activeWidthRounded, pillarboxWidth
    };
};

export default calculate;
