import React, { useEffect } from 'react';
// import PropTypes from 'prop-types';
import moment from 'moment';
import { useQuery } from '@apollo/client';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Heading, Body, Subtitle } from '../modules/Typography';
import Table from '../modules/Table';
import Paper from '../modules/Paper';
import Loading from '../modules/Loading';
import { GET_CATEGORY } from '../../queries';
import parseMarkdown from '../../utils/markdownParser';

export const CategoryContext = React.createContext();

const Category = props => {
    const { catSlug } = useParams();
    const navigate = useNavigate();
    const { data, loading } = useQuery(GET_CATEGORY, { variables: { slug: catSlug } });

    useEffect(() => {
        if (categorieses && !categorieses.data.length) {
            navigate('/');
        }
    }, [categorieses]);

    if (loading) return <Loading />;
    const { categorieses } = data;

    const category = categorieses.data[0];
    return (
        <div>
            <Helmet><title>Disney Media Tech Specs</title></Helmet>
            <div className='Document'>
                <Heading number={5} heavy block className='Document__title'>{category.attributes.name}</Heading>
                <Paper className='flex column' elevation={6}>
                    <Body className='Document__text' number={2}>{parseMarkdown(category.attributes.description)}</Body>
                    {category.attributes.subcategoryContainer
                        .filter(container => container.subcategory)
                        .map(container => (
                            <div className='Document__Subcategory' key={container.subcategory.data.id}>
                                <Link to={`/${category.attributes.slug}/${container.subcategory.data.attributes.slug}/${container.subcategory.data.attributes.documentContainer[0].document.data.attributes.slug}`}>
                                    <Subtitle number={3}>{container.subcategory.data.attributes.name}</Subtitle>
                                </Link>
                                <Body className='Document__text' number={2}>{parseMarkdown(container.subcategory.data.attributes.description)}</Body>
                            </div>
                        ))}
                </Paper>
                {category.attributes.updates.length ? (
                    <Paper className='Document__Updates'>
                        <Heading number={5} heavy block className='Document__Updates__title'>Updates</Heading>
                        <Table
                            data={category.attributes.updates
                                .sort((a, b) => {
                                    if (moment(a.date).isBefore(moment(b.date))) {
                                        return -1;
                                    }
                                    return 1;
                                })
                                .map(update => ([
                                    moment(update.date).format('L'),
                                    update.category,
                                    parseMarkdown(update.description)
                                ]))}
                            className='Document__Updates__table'
                        />
                    </Paper>
                ) : ''}
            </div>
        </div>
    );
};

Category.propTypes = {

};

export default Category;
