import AJV from 'ajv';

const ajv = new AJV({ allErrors: true, verbose: true });
const pillarboxingSchema = {
    type: 'object',
    properties: {
        width: {
            type: 'integer',
            minimum: 0,
            maximum: 99999
        },
        height: {
            type: 'integer',
            minimum: 0,
            maximum: 99999
        },
        aspectRatio: {
            type: 'number',
            minimum: 0,
            maximum: 99999
        }
    },
    required: ['width', 'height', 'aspectRatio'],
    additionalProperties: false
};

export default ajv.compile(pillarboxingSchema);
