import AJV from 'ajv';

const ajv = new AJV({ allErrors: true, verbose: true });
const activeAreaAspectRatioSchema = {
    type: 'object',
    properties: {
        width: {
            type: 'integer',
            minimum: 0,
            maximum: 99999
        },
        height: {
            type: 'integer',
            minimum: 1,
            maximum: 99999
        }
    },
    required: ['width', 'height'],
    additionalProperties: false
};

export default ajv.compile(activeAreaAspectRatioSchema);
