export const documentSpecsFilter = (document) => {
    if (document) {
        const sections = document.documents.data[0].attributes.section;
        const activeSections = sections
            .map((section) => {
                const activeSpecContainers = section.specContainer.filter(
                    (spec) => spec.spec.data.attributes.publishedAt !== null
                );
                const sectionResult = {
                    ...section,
                    specContainer: [...activeSpecContainers]
                };
                return sectionResult;
            })
            .filter((section) => section.specContainer.length !== 0);
        const filteredDocument = {
            ...document.documents.data[0],
            attributes: {
                ...document.documents.data[0].attributes,
                section: activeSections
            }
        };
        return { ...document, documents: { data: [filteredDocument] } };
    }
};
