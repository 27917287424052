function bitsToBytes (bits) {
    return bits / 8;
}
function bytesToMegaBytes (bytes) {
    return bytes / 1_000_000;
}
function megaBytesToBytes (MB) {
    return MB * 1_000_000;
}
function bytesToTeraBytes (bytes) {
    return bytes / 1_000_000_000_000;
}
function teraBytesToGigaBytes (bytes) {
    return bytes * 1_000;
}
function minutesToSeconds (minutes) {
    return minutes * 60;
}

const colorProfiles = 3;
function _singleFrameSize (width, height, bitDepth) { // returns in MB
    const bits = width * height * (bitDepth * colorProfiles);
    const bytes = bitsToBytes(bits);
    return bytesToMegaBytes(bytes);
}

function _totalSize (singleFrameSize, playbackRate, runTime) { // returns in TB
    const seconds = minutesToSeconds(runTime);
    const megaBytes = singleFrameSize * playbackRate * seconds;
    const bytes = megaBytesToBytes(megaBytes);
    return bytesToTeraBytes(bytes);
}

// width        -- integer
// height       -- integer
// bitDepth     -- select values
// playbackRate -- select values / user input (limit to 3 digits/3 decimals)
// runTime      -- in minutes
const calculate = ({ width, height, bitDepth, playbackRate, runTime }) => {
    const singleFrameSize = _singleFrameSize(width, height, bitDepth);
    const teraBytes = _totalSize(singleFrameSize, playbackRate, runTime);

    const totalSize = teraBytes < 1
        ? `${teraBytesToGigaBytes(teraBytes).toFixed(3)} GB`
        : `${teraBytes.toFixed(3)} TB`;

    return {
        singleFrameSize: `${singleFrameSize} MB`,
        totalSize
    };
};

export default calculate;
