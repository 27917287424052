import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import Creatable from 'react-select/creatable';

import { GET_TIPS } from '../../../../queries';
import Loading from '../../../modules/Loading';
import Tips from '../Tips';
import { Caption, Heading, Subtitle } from '../../../modules/Typography';
import TextField from '../../../modules/TextField';
import Button from '../../../modules/Button';
import { useFocus } from '../../../../utils/hooks';
import xCircleIcon from '../../../../assets/icon_x_circle.svg';

import validator from './validator';
import calculate from './formulas';

const initialResults = {
    singleFrameSize: 0,
    totalSize: 0
};

const UncompressedFrameSize = props => {
    const { data, loading } = useQuery(GET_TIPS, { variables: { calculator: 'Uncompressed Frame Size' } });

    const [width, setWidth] = useState('');
    const [height, setHeight] = useState('');
    const [bitDepth, setBitDepth] = useState('');
    const [playbackRate, setPlaybackRate] = useState('');
    const [runTime, setRunTime] = useState('');
    const [results, setResults] = useState(initialResults);
    const [errors, setErrors] = useState({});

    const [widthRef, setWidthFocus] = useFocus();
    const [heightRef, setHeightFocus] = useFocus();
    const [runTimeRef, setRunTimeFocus] = useFocus();

    if (loading) return <Loading />;

    const handleCalculation = e => {
        e.preventDefault();

        const _errors = {};
        setErrors(_errors);

        const params = {
            width: width.length === 0 ? '' : Number(width),
            height: height.length === 0 ? '' : Number(height),
            bitDepth: (bitDepth && bitDepth.value.length !== 0) ? Number(bitDepth.value) : '',
            playbackRate: (playbackRate && playbackRate.value.length !== 0) ? Number(playbackRate.value) : '',
            runTime: runTime.length === 0 ? '' : Number(runTime)
        };

        const isValid = validator(params);
        if (!isValid) {
            for (const error of validator.errors) {
                const { instancePath, message } = error;
                _errors[instancePath] = { message };
            }

            setErrors(_errors);
            return;
        }

        const results = calculate(params);
        setResults(results);
    };

    const handleReset = e => {
        e.preventDefault();
        setWidth('');
        setHeight('');
        setBitDepth('');
        setPlaybackRate('');
        setRunTime('');
        setErrors({});
        setResults(initialResults);
    };

    const bitDepthOptions = [
        { value: '8', label: '8' },
        { value: '10', label: '10' },
        { value: '12', label: '12' },
        { value: '16', label: '16' }
    ];
    const playbackOptions = [
        { value: '23.976', label: '23.976' },
        { value: '24', label: '24' },
        { value: '25', label: '25' },
        { value: '48', label: '48' }
    ];
    const colourStyles = {
        control: (styles) => ({
            ...styles,
            border: 0,
            boxShadow: 'unset',
            ':hover': {
                border: 0,
                boxShadow: 'unset'
            }
        })
    };

    const tips = data?.calculatorses.data[0]?.attributes.tips ?? [];
    return (
        <>
            <section className='calculator__wrapper'>

                <form className='calculator__form'>
                    <Heading number={5} heavy block className='calculator__form__title'>Uncompressed Frame Size Calculator</Heading>

                    <div className='calculator__input__wrapper'>
                        <TextField
                            className='calculator__input'
                            label='Width'
                            type='text'
                            value={width}
                            onChange={e => setWidth(e.target.value)}
                            error={Boolean(errors['/width']) ?? false}
                            errorMessage={errors['/width']?.message}
                            ref={widthRef}
                            required
                        />
                        <Caption className='calculator__input__helper-text'>Enter frame width</Caption>
                        {
                            width.length !== 0 && Boolean(errors['/width']) &&
                            <img
                                className='clear-input' alt='calculator icon'
                                src={xCircleIcon} width='14px' height='14px'
                                onClick={() => {
                                    setWidth('');
                                    setWidthFocus();
                                }}
                            />
                        }
                    </div>
                    <div className='calculator__input__wrapper'>
                        <TextField
                            className='calculator__input'
                            label='Height'
                            type='text'
                            value={height}
                            onChange={e => setHeight(e.target.value)}
                            error={Boolean(errors['/height']) ?? false}
                            errorMessage={errors['/height']?.message}
                            ref={heightRef}
                            required
                        />
                        <Caption className='calculator__input__helper-text'>Enter frame height</Caption>
                        {
                            height.length !== 0 && Boolean(errors['/height']) &&
                            <img
                                className='clear-input' alt='calculator icon'
                                src={xCircleIcon} width='14px' height='14px'
                                onClick={() => {
                                    setHeight('');
                                    setHeightFocus();
                                }}
                            />
                        }
                    </div>
                    <div className='calculator__input__wrapper'>
                        <div className='TextField calculator__input TextField--padded'>
                            <label className='TextField__label calculator__input__label'>
                                <span className='Typography Typography--caption1 Typography--color-default'>Bit Depth</span>
                            </label>

                            <div className='TextField__style-wrapper TextField__style-wrapper--default selectable'>
                                <Creatable
                                    className='TextField__input'
                                    styles={colourStyles}
                                    isClearable
                                    value={bitDepth}
                                    onChange={opt => setBitDepth(opt)}
                                    options={bitDepthOptions}
                                />
                            </div>
                        </div>
                        <Caption className='calculator__input__helper-text'>Enter bit depth per RGB channel</Caption>
                        {
                            Boolean(errors['/bitDepth']) ?? false
                                ? <span className='Typography Typography--caption2 Typography--color-default TextField__error Typography--error'>
                                    {errors['/bitDepth']?.message}
                                </span>
                                : null
                        }
                    </div>
                    <div className='calculator__input__wrapper'>
                        <div className='TextField calculator__input TextField--padded'>
                            <label className='TextField__label calculator__input__label'>
                                <span className='Typography Typography--caption1 Typography--color-default'>Playback Rate</span>
                            </label>

                            <div className='TextField__style-wrapper TextField__style-wrapper--default selectable'>
                                <Creatable
                                    className='TextField__input'
                                    styles={colourStyles}
                                    isClearable
                                    value={playbackRate}
                                    onChange={opt => setPlaybackRate(opt)}
                                    options={playbackOptions}
                                />
                            </div>
                        </div>
                        <Caption className='calculator__input__helper-text'>Enter frame rate</Caption>
                        {
                            Boolean(errors['/playbackRate']) ?? false
                                ? <span className='Typography Typography--caption2 Typography--color-default TextField__error Typography--error'>
                                    {errors['/playbackRate']?.message}
                                </span>
                                : null
                        }
                    </div>
                    <div className='calculator__input__wrapper last'>
                        <TextField
                            className='calculator__input'
                            label='Run Time'
                            type='text'
                            value={runTime}
                            onChange={e => setRunTime(e.target.value)}
                            error={Boolean(errors['/runTime']) ?? false}
                            errorMessage={errors['/runTime']?.message}
                            ref={runTimeRef}
                            required
                        />
                        <Caption className='calculator__input__helper-text'>Enter run time in minutes</Caption>
                        {
                            runTime.length !== 0 && Boolean(errors['/runTime']) &&
                            <img
                                className='clear-input' alt='calculator icon'
                                src={xCircleIcon} width='14px' height='14px'
                                onClick={() => {
                                    setRunTime('');
                                    setRunTimeFocus();
                                }}
                            />
                        }
                    </div>

                    <div className='calculator__form__controls'>
                        <Button
                            className='calculator__form__control-calculate'
                            type='primary'
                            color='primary-light'
                            text='Calculate'
                            onClick={(e) => handleCalculation(e)}
                        />
                        <Button
                            className='calculator__form__control-reset'
                            type='secondary'
                            color='primary-light'
                            text='Reset'
                            onClick={(e) => handleReset(e)}
                        />
                    </div>
                </form>

                <aside className='calculator__results'>
                    <Heading number={5} heavy block className='calculator__results__title'>Results</Heading>

                    <ul>
                        <li className='calculator__result-item'>
                            <Subtitle>Single Frame Size</Subtitle>
                            <Subtitle>{results.singleFrameSize}</Subtitle>
                        </li>
                        <li className='calculator__result-item'>
                            <Subtitle>Total Frame Size</Subtitle>
                            <Subtitle>{results.totalSize}</Subtitle>
                        </li>
                    </ul>

                </aside>
            </section>

            {
                !tips || tips.length === 0
                    ? null
                    : <Tips tips={tips} />
            }
        </>
    );
};

export default UncompressedFrameSize;
