import React, { useRef, useState, useContext } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useNavigate, Link } from 'react-router-dom';
import logo from '../../assets/Specs_Logo.png';
import calculatorIcon from '../../assets/icon_calculator.svg';
import Tooltip from '../modules/Tooltip';
import TextField from '../modules/TextField';
import IconButton from '../modules/IconButton';
import Button from '../modules/Button';
import { WidthContext } from '../../contexts/WidthContextProvider';
import Icon from '../modules/Icon';

const TopNav = ({ isSearchPanelOpen, setSearchPanelOpen, handleMobileMenu }) => {
    const { isMobile } = useContext(WidthContext);
    const navigate = useNavigate();
    const [searchTerm, setSearchTerm] = useState('');
    const searchInput = useRef(null);

    const handleSubmit = () => {
        const _searchTerm = searchTerm.replace(' ', '-');
        navigate(`/search-results/${_searchTerm}`);
        setSearchTerm('');
    };
    const handleSearchIconClick = e => {
        if (searchTerm === '') {
            searchInput.current.focus();
        } else {
            handleSubmit();
        }
    };

    return (
        <>

            {
                isMobile
                    ? (
                        <>
                            <div className='SideNav__icons flex'>
                                <div className='SideNav__logo'>
                                    <Link to='/'>
                                        <img src={logo} alt='Walt Disney Tech' />
                                    </Link>
                                </div>
                                <div className='flex'>
                                    <IconButton className='TopNav__mobile' name='search' onClick={() => setSearchPanelOpen(!isSearchPanelOpen)} />
                                    <IconButton className='SideNav__menu pointer flex' name='menu' onClick={handleMobileMenu} />
                                </div>
                            </div>
                        </>
                    )

                    : (
                        <>
                            <div className='SideNav__logo'>
                                <Link to='/'>
                                    <img className='flex' src={logo} alt='Walt Disney Tech' />
                                </Link>
                            </div>

                            <div className='TopNav flex align-center'>
                                <Tooltip content='Search' direction='down'>
                                    <Icon name='search' className='Top' onClick={handleSearchIconClick} />
                                    <TextField
                                        ref={searchInput}
                                        placeholder='Search Specs ...'
                                        className='TopNav__Search'
                                        padded={false}
                                        onChange={e => setSearchTerm(e.target.value)}
                                        value={searchTerm}
                                        onSubmit={handleSubmit}
                                    />
                                </Tooltip>
                                <div className='flex'>
                                    <Tooltip content='Calculators' direction='down'>
                                        <Link className='TopNav__calc_icon' to='/calculators'>
                                            <img src={calculatorIcon} alt='calculator icon' />
                                        </Link>
                                    </Tooltip>
                                    <Tooltip content='Glossary' direction='down'>
                                        <IconButton name='book' className='TopNav__book' link='/glossary' />
                                    </Tooltip>
                                    <Tooltip content='Contact Us' direction='down'>
                                        <IconButton name='email' link='/contact' />
                                    </Tooltip>
                                </div>

                            </div>
                        </>)
            }

            {!isSearchPanelOpen && isMobile &&
            <div className='SideNav__search__container'>
                <TextField
                    ref={searchInput}
                    placeholder='Type to Search...'
                    icon='search'
                    className='TopNav__Search mobile'
                    padded={false}
                    onChange={e => setSearchTerm(e.target.value)}
                    value={searchTerm}
                    onSubmit={handleSubmit}
                />
                <hr className='SideNav__divider' />
                <Button className='TopNav__button' onClick={handleSubmit} text='Search' type='surface' />
            </div>}
        </>
    );
};

TopNav.propTypes = {
    isSearchPanelOpen: PropTypes.bool,
    setSearchPanelOpen: PropTypes.func,
    handleMobileMenu: PropTypes.func
};

export default TopNav;
