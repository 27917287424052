import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';
import { GET_SPEC } from '../../queries';
import Loading from '../modules/Loading';
import Paper from '../modules/Paper';
import Spec from '../document/Spec';

const SpecPreview = props => {
    const { slug } = useParams();
    const [doc, setDoc] = useState(null);

    const [getDocument, { data, loading }] = useLazyQuery(GET_SPEC, { variables: { slug } });

    useEffect(() => {
        if (!doc || doc.attributes.slug !== slug) {
            getDocument();
        }
    }, [slug]);

    useEffect(() => {
        if (data) {
            setDoc(data.specs.data[0]);
        }
    }, [data]);

    if (loading) {
        return <Loading />;
    }

    return (

        <div className='Document'>
            <Paper elevation={1} className='Document__Paper'>
                {doc &&
                <Spec spec={{ content: doc.attributes.content }} />}
            </Paper>
        </div>
    );
};

export default SpecPreview;
