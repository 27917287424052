import React, { useEffect, useRef, useState } from 'react';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';

const VideoJS = ({ options }) => {
  const videoRef = useRef(null);
  const playerRef = useRef(null);
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    if (videoRef.current && !playerRef.current && isReady) {
      playerRef.current = videojs(videoRef.current, options);
      playerRef.current.on('error', () => {
        console.error('Video.js error:', playerRef.current.error());
      });
      playerRef.current.on('loadedmetadata', () => {
        const videoWidth = playerRef.current.videoWidth();
        const videoHeight = playerRef.current.videoHeight();
        const videoAspectRatio = videoWidth / videoHeight;
        if (videoAspectRatio > 0.75) {
          playerRef.current.fluid(true);
        }
      });
    }

    return () => {
      if (playerRef.current) {
        playerRef.current.dispose();
        playerRef.current = null;
      }
    };
  }, [options, isReady]);

  useEffect(() => {
    if (videoRef.current) {
      setIsReady(true);
    }
  }, []);

  return (
    <div data-vjs-player>
      <video
        style={{
          width: '100%',
          aspectRatio: '16/9',
          backgroundColor: 'transparent'
        }}
        ref={videoRef}
        className='video-js vjs-default-skin'
        controls
      />
    </div>
  );
};

export default VideoJS;
