import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import IconButton from './IconButton';
import { Subtitle } from './Typography';

const TabBar = ({ tabs, links, icons, selectedTab, updateTab, className, fitted }) => {
    const [ghostStyle, setGhostStyle] = useState({});
    const tabBarRef = useRef(null);
    const ghostRef = useRef(null);

    useEffect(() => {
        if (tabBarRef.current) {
            // remove all selected styling
            [...tabBarRef.current.querySelectorAll('.TabBar__tab')].forEach(aTab => {
                aTab.classList.remove('TabBar__tab--selected');
            });

            // transition the ghost
            const parentBox = tabBarRef.current.getBoundingClientRect();
            const tab = tabBarRef.current.querySelector(`.TabBar__tab[data-value='${selectedTab}']`);
            const tabBox = tab.getBoundingClientRect();
            ghostRef.current.style.opacity = 1;
            setGhostStyle({
                left: tabBox.left - parentBox.left,
                width: tabBox.width
            });

            // add event listener to hide ghost and turn back on selected styling
            const event = () => {
                ghostRef.current.style.opacity = 0;
                tab.classList.add('TabBar__tab--selected');
                tabBarRef.current.removeEventListener('transitionend', event);
                ;
            };

            tabBarRef.current.addEventListener('transitionend', event);
        }
    }, [selectedTab]);

    return (
        <div className={`TabBar ${className} ${fitted ? 'TabBar--fitted' : ''}`} ref={tabBarRef}>
            {tabs.map((tab, index) => icons[index] ? (
                <IconButton
                    name={icons[index]}
                    className='TabBar__tab TabBar__icon'
                    dataValue={tab}
                    key={tab}
                    onClick={updateTab ? (e) => {
                        e.preventDefault();
                        updateTab(tab);
                    } : null}
                    size='small'
                />

            ) : (
                <LinkWrapper
                    link={links[index]}
                    key={tab}
                    data-value={tab}
                    className='Typography--caption1 TabBar__tab'
                    onClick={updateTab ? (e) => {
                        e.preventDefault();
                        updateTab(tab);
                    } : null}
                >
                    <Subtitle number={1} className='TabBar__tab__text'>{tab}</Subtitle>
                </LinkWrapper>
            ))}

            <div className='TabBar__ghost TabBar__tab--selected' style={ghostStyle} ref={ghostRef} />
        </div>
    );
};

const LinkWrapper = ({ children, link, ...props }) => link ? (
    <Link to={link} {...props}>{children}</Link>
) : (
    <button {...props}>{children}</button>
);

export default TabBar;

LinkWrapper.propTypes = {
    link: PropTypes.string
};

TabBar.propTypes = {
    // all the available tabs
    tabs: PropTypes.arrayOf(PropTypes.string),
    icons: PropTypes.arrayOf(PropTypes.string),

    // optional links if the tabs should route somewhere
    links: PropTypes.arrayOf(PropTypes.string),

    // tab to be highlighted as "selected"
    selectedTab: PropTypes.string,

    // function to handle tab change
    // (tab) => alert(`${tab} has been selected`)
    updateTab: PropTypes.func,

    // className to pass down to tabs
    className: PropTypes.string,

    // whether or not the tabs options are all fitted to be equal
    fitted: PropTypes.bool
};

TabBar.defaultProps = {
    tabs: [],
    links: [],
    icons: [],
    className: '',
    fitted: false
};
