import { gql } from '@apollo/client';

export const GET_DOCUMENT = gql`
  query document($slug: String) {
    documents(filters: { slug: { eq: $slug } }, publicationState: PREVIEW) {
      data {
        id
        attributes {
          name
          description
          fullDocumentPrint
          updatedAt
          publishDate
          pdfDownload {
            data {
              attributes {
                url
              }
            }
          }
          subcategories {
            data {
              attributes {
                name
                slug
                category {
                  data {
                    attributes {
                      name
                      slug
                    }
                  }
                }
              }
            }
          }
          updates {
            date
            publishDate
            category
            description
          }
          content {
            ... on ComponentLayoutTextBox {
              text
            }
            ... on ComponentLayoutTable {
              title
              footnote
              header {
                column1
                column2
                column3
                column4
                column5
              }
              row(pagination: { start: 0, limit: 500 }) {
                column1
                column2
                column3
                column4
                column5
              }
            }
            ... on ComponentLayoutSpecImage {
              image {
                data {
                  attributes {
                    url
                  }
                }
              }
            }
            ... on ComponentLayoutPdf {
              name
              file {
                data {
                  attributes {
                    url
                    name
                  }
                }
              }
            }
            ... on ComponentLayoutVideo {
              url
              title
              description
              uuid
            }
          }
          section(pagination: { start: 0, limit: 20 }) {
            name
            slug
            specContainer(pagination: { start: 0, limit: 20 }) {
              spec {
                data {
                  id
                  attributes {
                    slug
                    name
                    shortName
                    publishedAt
                    content {
                      ... on ComponentLayoutTextBox {
                        text
                      }
                      ... on ComponentLayoutTable {
                        title
                        footnote
                        header {
                          column1
                          column2
                          column3
                          column4
                          column5
                        }
                        row(pagination: { start: 0, limit: 500 }) {
                          column1
                          column2
                          column3
                          column4
                          column5
                        }
                      }
                      ... on ComponentLayoutSpecImage {
                        image {
                          data {
                            attributes {
                              url
                            }
                          }
                        }
                      }
                      ... on ComponentLayoutPdf {
                        name
                        file {
                          data {
                            attributes {
                              url
                              name
                            }
                          }
                        }
                      }
                      ... on ComponentLayoutVideo {
                        url
                        title
                        description
                        uuid
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_SPEC = gql`
  query spec($slug: String) {
    specs(filters: { slug: { eq: $slug } }, publicationState: PREVIEW) {
      data {
        id
        attributes {
          slug
          name
          shortName
          content {
            ... on ComponentLayoutTextBox {
              text
            }
            ... on ComponentLayoutTable {
              title
              footnote
              header {
                column1
                column2
                column3
                column4
                column5
              }
              row(pagination: { start: 0, limit: 500 }) {
                column1
                column2
                column3
                column4
                column5
              }
            }
            ... on ComponentLayoutSpecImage {
              image {
                data {
                  attributes {
                    url
                  }
                }
              }
            }
            ... on ComponentLayoutPdf {
              name
              file {
                data {
                  attributes {
                    url
                    name
                  }
                }
              }
            }
            ... on ComponentLayoutVideo {
              url
              title
              description
              uuid
            }
          }
        }
      }
    }
  }
`;

export const UPDATE_HOME = gql`
  mutation updateHome($response: String) {
    updateHome(response: $response) {
      response
    }
  }
`;
