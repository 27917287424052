import React, { createRef, useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Subtitle } from './Typography';
import Icon from './Icon';
import { CSSTransition } from 'react-transition-group';

const noIcon = ['Contact', 'Glossary'];
const Accordion = ({ children, padded, slug, title, type }) => {
    const { pathname } = useLocation();
    const [open, setOpen] = useState(false);
    const itemRef = createRef(null);
    const paths = pathname.split('/');
    const cat = paths.length > 1 ? paths[1] : '';

    useEffect(() => {
        if (cat === slug) {
            setOpen(true);
        } else {
            setOpen(false);
        }
    }, [pathname]);

    return (
        <div className={`Accordion Accordion--${type} ${padded ? 'Accordion--padded' : ''} ${!open ? 'Accordion--closed' : ''}`}>
            <div className='Accordion__header' onClick={() => setOpen(!open)}>
                {
                    noIcon.includes(title)
                        ? null
                        : <Icon name={open ? 'caret-down' : 'caret-right'} />
                }
                {
                    slug === 'calculators'
                        ? <Subtitle number={2} className={cat === slug ? 'active' : ''}>{title}</Subtitle>
                        : <Link to={`/${slug}`}>
                            <Subtitle number={2} className={cat === slug ? 'active' : ''}>{title}</Subtitle>
                          </Link>
                }
            </div>
            <CSSTransition
                nodeRef={itemRef}
                in={open}
                timeout={{
                    enter: 300,
                    exit: 0
                }} classNames='Accordion' unmountOnExit
            >
                <div ref={itemRef}>
                    <div className='Accordion__content'>
                        {children}
                    </div>
                </div>
            </CSSTransition>
        </div>
    );
};

Accordion.propTypes = {
    // title of the accordion
    title: PropTypes.string,

    // style of the accordion
    type: PropTypes.oneOf(['default']),

    // whether or not the accordion is padded below
    padded: PropTypes.bool,

    children: PropTypes.any,
    slug: PropTypes.string

};
Accordion.defaultProps = {
    type: 'default',
    padded: false
};

export default Accordion;
