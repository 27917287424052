import React from 'react';
import PropTypes from 'prop-types';
import parseMarkdown from '../../utils/markdownParser';
import Table from '../modules/Table';
import Icon from '../modules/Icon';
import { Body, Heading } from '../modules/Typography';
import VideoJS from '../modules/VideoJS';

const Spec = (props) => {
  if (
    (props.spec.data === undefined ||
      props.spec.data.attributes === undefined) &&
    props.spec.content === undefined
  ) {
    console.log('Ignoring empty spec: ', props.spec);
    return '';
  }

  var content = [];
  if (
    props.spec.data !== undefined &&
    props.spec.data.attributes !== undefined
  ) {
    content = props.spec.data.attributes.content;
  } else if (props.spec.content !== undefined) {
    content = props.spec.content;
  }

  // console.log('Processing content: ', content);

  return (
    <div id={`toc_${props.count}`} className='Document__ProgramLayout'>
      {content.map((block, idx) => {
        switch (block.__typename) {
        case 'ComponentLayoutTextBox':
          return (
            <div
              className={`Document__Block${props.printed ? '__print' : ''}`}
              key={idx}
            >
              {parseMarkdown(block.text)}
            </div>
          );
        case 'ComponentLayoutTable':
          return (
            <div
              className={`Document__Block${props.printed ? '__print' : ''}`}
              key={`${block.title}-${Math.random()}`}
            >
              <Table
                title={block.title}
                className='Document__Requirements__Table'
                columns={Object.values(block.header || []).filter(
                  (name) => name !== 'ComponentTableItems'
                )}
                data={block.row.map((row, idx) =>
                  Object.values(row).filter(
                    (name) => name && name !== 'ComponentTableItems'
                  )
                )}
                footnote={block.footnote}
              />
            </div>
          );
        case 'ComponentLayoutSpecImage':
          return (
            <div
              className={`Document__Block${props.printed ? '__print' : ''}`}
              key={`${block.image.data.attributes.url}`}
            >
              <img src={block.image.data.attributes.url} />
            </div>
          );
        case 'ComponentLayoutPdf':
          return (
            <div
              className={`Document__Block${props.printed ? '__print' : ''}`}
              key={`${block.file.data.attributes.url}`}
            >
              <a
                className='Document__PDF__link'
                href={block.file.data.attributes.url}
                download
                target='_blank'
                rel='noreferrer'
              >
                <Icon name='file-pdf' className='primary-light-text' />
                <Body color='primary-light'>
                  {block.name || block.file.data.attributes.name}
                </Body>
              </a>
            </div>
          );
        case 'ComponentLayoutVideo':
          return (
            !props.printed && (
              <div
                className='Document__Block'
                key={Math.random().toString().substr(2, 9)}
              >
                <div
                  style={{
                    padding: '1rem 4rem',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '1rem',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                >
                  {block.title && block.title !== '' && (
                    <Heading number={5} heavy block>
                      {block.title}
                    </Heading>
                  )}
                  <VideoJS
                    options={{
                      autoplay: false,
                      controls: true,
                      responsive: true,
                      // fluid: true,
                      sources: [
                        {
                          src: block.url,
                          type: 'application/x-mpegURL'
                        }
                      ]
                    }}
                  />
                  {block.description && block.description !== '' && (
                    <Body>{block.description}</Body>
                  )}
                </div>
              </div>
            )
          );
        default:
          return '';
        }
      })}
    </div>
  );
};
Spec.propTypes = {
  spec: PropTypes.object,
  count: PropTypes.number,
  printed: PropTypes.bool
};

export default Spec;
