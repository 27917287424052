import React, { useContext, useState } from 'react';
import { useQuery } from '@apollo/client';

import { WidthContext } from '../../../../contexts/WidthContextProvider';
import { GET_TIPS } from '../../../../queries';
import Loading from '../../../modules/Loading';
import Tips from '../Tips';
import { Caption, Heading, Subtitle } from '../../../modules/Typography';
import TextField from '../../../modules/TextField';
import Button from '../../../modules/Button';
import { useFocus } from '../../../../utils/hooks';
import xCircleIcon from '../../../../assets/icon_x_circle.svg';

import validator from './validator';
import calculate from './formulas';

const initialResults = {
    activeLines: 0,
    activeLinesRound: 0,
    firstLine: 0,
    lastLine: 0,
    letterboxTotal: 0,
    letterboxHalf: 0,
    titleSafe: 0,
    titleSafeRounded: 0,
    titleSafeTotal: 0,
    titleSafeHalf: 0,
    titleSafeFirst: 0,
    titleSafeLast: 0,
    titleSafeLeft: 0,
    titleSafeRight: 0,
    actionSafe: 0,
    actionSafeRounded: 0,
    actionSafeTotal: 0,
    actionSafeHalf: 0,
    actionSafeFirst: 0,
    actionSafeLast: 0,
    actionSafeLeft: 0,
    actionSafeRight: 0

};

const ActiveLine = props => {
    const { data, loading } = useQuery(GET_TIPS, { variables: { calculator: 'Active Line' } });
    const { isMobile } = useContext(WidthContext);

    const [width, setWidth] = useState('');
    const [height, setHeight] = useState('');
    const [aspectRatio, setAspectRatio] = useState('');
    const [results, setResults] = useState(initialResults);
    const [errors, setErrors] = useState({});

    const [widthRef, setWidthFocus] = useFocus();
    const [heightRef, setHeightFocus] = useFocus();
    const [aspectRatioRef, setAspectRatioFocus] = useFocus();

    if (loading) return <Loading />;

    const handleCalculation = e => {
        e.preventDefault();

        const _errors = {};
        setErrors(_errors);

        const params = {
            width: width.length === 0 ? '' : Number(width),
            height: height.length === 0 ? '' : Number(height),
            aspectRatio: aspectRatio.length === 0 ? '' : Number(aspectRatio)
        };

        const isValid = validator(params);
        if (!isValid) {
            for (const error of validator.errors) {
                const { instancePath, message } = error;
                _errors[instancePath] = { message };
            }

            setErrors(_errors);
            return;
        }

        const results = calculate(params);
        setResults(results);
    };

    const handleReset = e => {
        e.preventDefault();
        setWidth('');
        setHeight('');
        setAspectRatio('');
        setErrors({});
        setResults(initialResults);
    };

    const tips = data?.calculatorses.data[0]?.attributes.tips ?? [];
    return (
        <>
            <section className={`calculator__wrapper ${isMobile ? 'isMobile' : ''}`}>

                <form className='calculator__form'>
                    <Heading number={5} heavy block className='calculator__form__title'>Active Line Calculator</Heading>

                    <div className='calculator__input__wrapper'>
                        <TextField
                            className='calculator__input'
                            label='Width'
                            type='text'
                            value={width}
                            onChange={e => setWidth(e.target.value)}
                            error={Boolean(errors['/width']) ?? false}
                            errorMessage={errors['/width']?.message}
                            ref={widthRef}
                            required
                        />
                        <Caption className='calculator__input__helper-text'>Enter full frame width in pixels</Caption>
                        {
                            width.length !== 0 && Boolean(errors['/width']) &&
                            <img
                                className='clear-input' alt='calculator icon'
                                src={xCircleIcon} width='14px' height='14px'
                                onClick={() => {
                                    setWidth('');
                                    setWidthFocus();
                                }}
                            />
                        }
                    </div>
                    <div className='calculator__input__wrapper'>
                        <TextField
                            className='calculator__input'
                            label='Height'
                            type='text'
                            value={height}
                            onChange={e => setHeight(e.target.value)}
                            error={Boolean(errors['/height']) ?? false}
                            errorMessage={errors['/height']?.message}
                            ref={heightRef}
                            required
                        />
                        <Caption className='calculator__input__helper-text'>Enter full frame height in pixels</Caption>
                        {
                            height.length !== 0 && Boolean(errors['/height']) &&
                            <img
                                className='clear-input' alt='calculator icon'
                                src={xCircleIcon} width='14px' height='14px'
                                onClick={() => {
                                    setHeight('');
                                    setHeightFocus();
                                }}
                            />
                        }
                    </div>
                    <div className='calculator__input__wrapper last'>
                        <TextField
                            className='calculator__input'
                            label='Aspect Ratio'
                            type='text'
                            value={aspectRatio}
                            onChange={e => setAspectRatio(e.target.value)}
                            error={Boolean(errors['/aspectRatio']) ?? false}
                            errorMessage={errors['/aspectRatio']?.message}
                            ref={aspectRatioRef}
                            required
                        />
                        <Caption className='calculator__input__helper-text'>Enter Aspect Ratio (e.g. 2.39)</Caption>
                        {
                            aspectRatio.length !== 0 && Boolean(errors['/aspectRatio']) &&
                            <img
                                className='clear-input' alt='calculator icon'
                                src={xCircleIcon} width='14px' height='14px'
                                onClick={() => {
                                    setAspectRatio('');
                                    setAspectRatioFocus();
                                }}
                            />
                        }
                    </div>

                    <div className='calculator__form__controls'>
                        <Button
                            className='calculator__form__control-calculate'
                            type='primary'
                            color='primary-light'
                            text='Calculate'
                            onClick={e => handleCalculation(e)}
                        />
                        <Button
                            className='calculator__form__control-reset'
                            type='secondary'
                            color='primary-light'
                            text='Reset'
                            onClick={e => handleReset(e)}
                        />
                    </div>
                </form>

                <aside className='calculator__results'>
                    <Heading number={5} heavy block className='calculator__results__title'>Results</Heading>

                    <ul>
                        <li className='calculator__result-item'>
                            <Subtitle>Active Lines</Subtitle>
                            <Subtitle>{results.activeLines}</Subtitle>
                        </li>
                        <li className='calculator__result-item'>
                            <Subtitle heavy>Active Lines (Rounded)</Subtitle>
                            <Subtitle heavy>{results.activeLinesRound}</Subtitle>
                        </li>
                        <li className='calculator__result-item'>
                            <Subtitle>First Line</Subtitle>
                            <Subtitle>{results.firstLine}</Subtitle>
                        </li>
                        <li className='calculator__result-item'>
                            <Subtitle>Last Line</Subtitle>
                            <Subtitle>{results.lastLine}</Subtitle>
                        </li>
                        <li className='calculator__result-item'>
                            <Subtitle>Letterbox Total</Subtitle>
                            <Subtitle>{results.letterboxTotal}</Subtitle>
                        </li>
                        <li className='calculator__result-item'>
                            <Subtitle>Letterbox Half</Subtitle>
                            <Subtitle>{results.letterboxHalf}</Subtitle>
                        </li>
                    </ul>
                    <ul className='calculator__column'>
                        <li className='calculator__result-item'>
                            <Subtitle heavy>Title Safe</Subtitle>
                        </li>
                        {/* <li className='calculator__result-item'>
                            <Subtitle>Title Safe</Subtitle>
                            <Subtitle heavy>{results.titleSafe}</Subtitle>
                        </li>
                        <li className='calculator__result-item'>
                            <Subtitle>Title Safe Rounded</Subtitle>
                            <Subtitle heavy>{results.titleSafeRounded}</Subtitle>
                        </li>
                        <li className='calculator__result-item'>
                            <Subtitle>Title Safe Total</Subtitle>
                            <Subtitle heavy>{results.titleSafeTotal}</Subtitle>
                        </li>
                        <li className='calculator__result-item'>
                            <Subtitle>Title Safe Half</Subtitle>
                            <Subtitle heavy>{results.titleSafeHalf}</Subtitle>
                        </li> */}
                        <li className='calculator__result-item'>
                            <Subtitle>Title Safe First Line (Top)</Subtitle>
                            <Subtitle heavy>{results.titleSafeFirst}</Subtitle>
                        </li>
                        <li className='calculator__result-item'>
                            <Subtitle>Title Safe Last Line (Bottom)</Subtitle>
                            <Subtitle heavy>{results.titleSafeLast}</Subtitle>
                        </li>
                        <li className='calculator__result-item'>
                            <Subtitle>Title Safe Right</Subtitle>
                            <Subtitle heavy>{results.titleSafeRight}</Subtitle>
                        </li>
                        <li className='calculator__result-item'>
                            <Subtitle>Title Safe Left</Subtitle>
                            <Subtitle heavy>{results.titleSafeLeft}</Subtitle>
                        </li>
                        <li className='calculator__result-item'>
                            <Subtitle heavy>Action Safe</Subtitle>
                        </li>
                        {/* <li className='calculator__result-item'>
                            <Subtitle>Action Safe</Subtitle>
                            <Subtitle heavy>{results.actionSafe}</Subtitle>
                        </li>
                        <li className='calculator__result-item'>
                            <Subtitle>Action Safe Rounded</Subtitle>
                            <Subtitle heavy>{results.actionSafeRounded}</Subtitle>
                        </li>
                        <li className='calculator__result-item'>
                            <Subtitle>Action Safe Total</Subtitle>
                            <Subtitle heavy>{results.actionSafeTotal}</Subtitle>
                        </li>
                        <li className='calculator__result-item'>
                            <Subtitle>Action Safe Half</Subtitle>
                            <Subtitle heavy>{results.actionSafeHalf}</Subtitle>
                        </li> */}
                        <li className='calculator__result-item'>
                            <Subtitle>Action Safe First Line (Top)</Subtitle>
                            <Subtitle heavy>{results.actionSafeFirst}</Subtitle>
                        </li>
                        <li className='calculator__result-item'>
                            <Subtitle>Action Safe Last Line (Bottom)</Subtitle>
                            <Subtitle heavy>{results.actionSafeLast}</Subtitle>
                        </li>
                        <li className='calculator__result-item'>
                            <Subtitle>Action Safe Right</Subtitle>
                            <Subtitle heavy>{results.actionSafeRight}</Subtitle>
                        </li>
                        <li className='calculator__result-item'>
                            <Subtitle>Action Safe Left</Subtitle>
                            <Subtitle heavy>{results.actionSafeLeft}</Subtitle>
                        </li>
                    </ul>

                </aside>
            </section>

            {
                !tips || tips.length === 0
                    ? null
                    : <Tips tips={tips} />
            }
        </>
    );
};

export default ActiveLine;
