import React from 'react';
// import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import { useQuery } from '@apollo/client';
import { Link, Element } from 'react-scroll';
import { GET_GLOSSARY } from '../../queries';
import { Heading } from '../modules/Typography';
import Paper from '../modules/Paper';
import Loading from '../modules/Loading';
import Table from '../modules/Table';

const Glossary = props => {
    const { data, loading } = useQuery(GET_GLOSSARY);

    if (loading) return <Loading />;
    const { section } = data.glossary.data.attributes;

    return (
        <div className='Glossary'>
            <Helmet><title>Disney Media Tech Specs | Glossary</title></Helmet>
            <Heading number={5} heavy block className='Document__title__glossary'>Glossary</Heading>
            <Paper className='flex column' elevation={6}>
                <div className='Document__alphabet flex'>
                    {section.map(sect => (
                        <Link
                            key={sect.title}
                            to={sect.id}
                            smooth
                            duration={500}
                        >
                            <div className='Glossary__Section__Title__Container'>
                                <Heading className='Glossary__Section__Title' number={5} heavy>{sect.title}</Heading>
                            </div>
                        </Link>
                    ))}
                </div>
                {section.map(sect => (
                    <Element name={sect.id} key={sect.id}>
                        <Table
                            title={sect.title}
                            className='Document__Requirements__Table'
                            columns={[]}
                            data={sect.row.map((row, idx) => (
                                [row.term, row.definition]
                            ))}
                        />
                    </Element>
                ))}
            </Paper>
        </div>
    );
};

Glossary.propTypes = {

};

export default Glossary;
