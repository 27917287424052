import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import { GET_CATEGORIES } from '../queries/categories';

const ignoreCategories = ['Integ', 'Disney Streaming'];
const CategoriesContext = React.createContext();
const CategoriesContextProvider = ({ children }) => {
    const { data, loading, error } = useQuery(GET_CATEGORIES);
    const [categories, setCategories] = useState([]);

    useEffect(() => {
        if (data) {
            const filteredCategories = data.categorieses.data.filter(category => {
                return !ignoreCategories.includes(category.attributes.name);
            });
            setCategories(filteredCategories);
        }
    }, [data]);

    if (loading) return null;
    if (error) console.error('error fetching categories');

    return (
        <CategoriesContext.Provider value={{ categories }}>
            {children}
        </CategoriesContext.Provider>
    );
};

CategoriesContextProvider.propTypes = {
    children: PropTypes.object
};

export default CategoriesContextProvider;
export { CategoriesContext };
