const calculate = ({ amount, conversionType }) => {
    const results = (amount * conversionType);

    let conversionResults = results;
    let roundedResults = Math.round(results);

    if (conversionType < 2) {
        conversionResults = `${results} fL`;
        roundedResults = `${roundedResults} fL`;
    } else {
        conversionResults = `${results} nits`;
        roundedResults = `${roundedResults} nits`;
    }
    return {
        conversionResults: conversionResults,
        roundedResults: roundedResults
    };
};

export default calculate;
