function _activeLines (width, aspectRatio) {
  const _activeLines = width / aspectRatio;
  return _activeLines.toFixed(5); // 5 decimal places
}

function _letterboxHalf (letterboxTotal) {
  const _letterboxHalf = letterboxTotal / 2;
  return Number(_letterboxHalf.toFixed(1)); // 1 decimal
}

const calculate = ({ width, height, aspectRatio }) => {
  const activeLines = _activeLines(width, aspectRatio);
  const activeLinesRound = Math.round(activeLines); // integer

  const letterboxTotal = height - Math.round(activeLines); // integer
  const letterboxHalf = _letterboxHalf(letterboxTotal);

  const firstLine = Math.round(letterboxHalf); // integer
  const lastLine = Math.round(height - letterboxHalf); // integer

  const titleSafe = activeLines * 0.9; // integer
  const actionSafe = activeLines * 0.93; // integer

  const titleSafeRounded = Math.round(titleSafe); // integer
  const actionSafeRounded = Math.round(actionSafe); // integer

  const titleSafeTotal = height - titleSafeRounded; // integer
  const actionSafeTotal = height - actionSafeRounded; // integer

  const titleSafeHalf = titleSafeTotal / 2; // integer
  const actionSafeHalf = actionSafeTotal / 2; // integer

  const titleSafeFirst = Math.round(titleSafeHalf); // integer
  const actionSafeFirst = Math.round(actionSafeHalf); // integer

  const titleSafeLast = Math.ceil(height - titleSafeHalf); // integer
  const actionSafeLast = Math.ceil(height - actionSafeHalf); // integer

  const titleSafeRight = width - 1 - Math.round(width * 0.05); // integer
  const actionSafeRight = width - 1 - Math.round(width * 0.035); // integer

  const titleSafeLeft = Math.round(width * 0.05); // integer
  const actionSafeLeft = Math.round(width * 0.035); // integer

  return {
    activeLines,
    activeLinesRound,
    firstLine,
    lastLine,
    letterboxTotal,
    letterboxHalf,
    titleSafe,
    titleSafeRounded,
    titleSafeTotal,
    titleSafeHalf,
    titleSafeFirst,
    titleSafeLast,
    titleSafeLeft,
    titleSafeRight,
    actionSafe,
    actionSafeRounded,
    actionSafeTotal,
    actionSafeHalf,
    actionSafeFirst,
    actionSafeLast,
    actionSafeLeft,
    actionSafeRight
  };
};

export default calculate;
