import { gql } from '@apollo/client';

export const GET_PERMALINKS = gql`
  query permalinks {
    permalinks {
      data {
        id
        attributes {
          name
          targetUrl
          uuid
          status
        }
      }
    }
  }
`;

export const GET_PERMALINK = gql`
  query permalink($uuid: String) {
    permalinks(filters: { uuid: { eq: $uuid } }) {
      data {
        id
        attributes {
          name
          targetUrl
          uuid
        }
      }
    }
  }
`;
