import React from 'react';
import Icon from './Icon';
import { Body } from './Typography';
import PropTypes from 'prop-types';

const ChipSet = props => (
    <div className='ChipSet'>
        {props.chips.map((chip, index) => (
            <div className={`ChipSet__chip ${props.editing ? 'ChipSet__chip--editing' : ''} ${props.activeChip === chip ? 'ChipSet__chip--active' : ''}`} key={index} onClick={() => props.handleClick(chip)}>
                <Body number={2} className='ChipSet__chip__text'>{chip}</Body>
                {props.editing && props.handleDelete && (
                    <button className='ChipSet__chip__delete' onClick={() => props.handleDelete(chip)}>
                        <Icon name='close-round' className='ChipSet__chip__delete__icon' />
                    </button>
                )}
            </div>
        ))}

    </div>
);

export default ChipSet;

ChipSet.propTypes = {
    // chips to iterate over
    chips: PropTypes.arrayOf(PropTypes.string),

    // whether or not the chips are in editing mode
    editing: PropTypes.bool,
    activeChip: PropTypes.string,

    // function to handle the deletion of a chip
    handleClick: PropTypes.func,
    handleDelete: PropTypes.func
};

ChipSet.defaultProps = {
    chips: [],
    editing: false,
    handleClick: () => {}
};
