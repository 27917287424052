import React from 'react';

export default function parseMarkdown (markdownText) {
  return (
    <div className='markdown-parser'>
      <div
        dangerouslySetInnerHTML={{
          __html: markdownTextTableParser(markdownText)
        }}
      />
    </div>
  );
}

const markdownTextTableParser = (markdownText) => {
  if (markdownText !== null) {
    let result;
    const tableRegex = /<table\b[^>]*>(.*?)<\/table>/g;
    const trRegex = /<tr\b[^>]*>(.*?)<\/tr>/g;
    const tdRegex = /<td\b[^>]*>(.*?)<\/td>/g;
    const tables = markdownText.match(tableRegex);
    if (tables !== null) {
      tables.map((table) => {
        const tableColumnsQty = table.match(trRegex)[0].match(tdRegex).length;
        if (tableColumnsQty === 2) {
          const tableColumnsContent = table.match(trRegex);
          const tableCells = table
            .match(tdRegex)
            .map((cell) => getPlainText(cell));
          const columnsValuesArrays = getColumnsValuesArrays(
            tableColumnsQty,
            tableCells
          );
          const widths = getLongestElements(columnsValuesArrays);
          const test = tableColumnsContent
            .map((row) => {
              return (
                '<tr>' +
                row
                  .match(tdRegex)
                  .map((cell, index) => {
                    const width = index % 2 === 0 ? widths[0] : widths[1];
                    return cell
                      .replace(/width\s*:\s*[^;]+;/g)
                      .replaceAll('<td style="', `<td style="${width}`);
                  })
                  .join('') +
                '</tr>'
              );
            })
            .join('');

          result = markdownText.replace(tableColumnsContent.join(''), test);
        } else {
          const tableWithFormat = table.replaceAll(
            '<td style="',
            `<td style="width:${100 / tableColumnsQty}%; `
          );
          result = markdownText.replace(table, tableWithFormat);
        }
      });
      return result;
    } else {
      return markdownText;
    }
  } else {
    return markdownText;
  }
};

const getPlainText = (richText) => {
  return richText.replace(/<[^>]*>/g, '');
};

const getColumnsValuesArrays = (totalColumns, cellsArray) => {
  return Array.from({ length: totalColumns }, (_, i) =>
    cellsArray.filter((_, index) => index % totalColumns === i)
  );
};

const getLongestElements = (columnsValuesArrays) => {
  const longestElements = columnsValuesArrays.map(
    (array) =>
      array.reduce(
        (max, element) => (element.length > max.length ? element : max),
        ''
      ).length
  );
  const elementsRatio = longestElements[0] / longestElements[1];
  if (elementsRatio < 0.15) {
    return ['width:auto; white-space:nowrap; ', 'width:auto; '];
  } else if (elementsRatio < 0.3) {
    return ['width: 30%; ', 'width: 70%; '];
  } else {
    return ['width: 50%; ', 'width: 50%; '];
  }
};
