import { gql } from '@apollo/client';

export const GET_GLOSSARY = gql`
    query glossary {
      glossary {
        data {
          attributes {
            description
            section(pagination: {start: 0, limit: 30}) {
              id
              title 
              row(pagination: {start: 0, limit: 50}) {
                term
                definition
              }
            }
          }
        }
      }
    }
`;
