import React from 'react';
import PropTypes from 'prop-types';
import Loading from '../modules/Loading';
import Paper from '../modules/Paper';
import Section from '../document/Section';
import Spec from '../document/Spec';

const Print = props => {
    const data = props.data;
    const sections = data.documents.data[0].attributes.section;
    const specs = data.documents.data[0].attributes.content;
    const fullPrint = props.documentPrint;

    if (!data || fullPrint === null) {
        return <Loading />;
    }

    return (

        <div className='Document'>
            {!fullPrint &&
                sections.length ? (
                    sections.map((section, i) => (
                        <Paper key={i} elevation={1} className={`${section.slug === props.printSectionSlug ? 'Document__Paper__Print' : 'Document__Paper__PrintSection'}`}>
                            <Section counter={i + 1} printed section={section.specContainer.map(spec => spec.spec)} />
                        </Paper>
                    ))

                ) : (
                    <>
                        <Spec printed spec={{ content: specs }} />
                    </>
                )}
            {fullPrint &&
                sections?.length > 0 &&
                    sections?.map((section, i) => (
                        <Paper key={Math.random()} elevation={1} className='Document__Paper__Print'>
                            <Section counter={i + 1} printed section={section.specContainer.map(spec => spec.spec)} />
                        </Paper>
                    )
                    )}
        </div>
    );
};

export default Print;

Print.propTypes = {
    data: PropTypes.object,
    printSectionSlug: PropTypes.string,
    documentPrint: PropTypes.bool
};
