import React from 'react';
import ListItem from './ListItem';
import PropTypes from 'prop-types';
import Button from './Button';

const List = ({ items, activeItem, className, onSelect, buttonText, handleButton, columns, disabled }) => {
    return (
        <ul className={`List ${className}`}>
            {!!buttonText && !!handleButton && (<Button disabled={disabled} text={buttonText} onClick={handleButton} />)}
            {items.map((item, index) => (
                <ListItem
                    key={`${item.value}-${index}`}
                    className={(item.value === activeItem ? 'active' : '') + (className ? ` ${className}__list-item` : ' ')}
                    onSelect={() => {
                        if (onSelect) {
                            onSelect(item.value);
                        }
                    }}
                    link={item.link}
                    {...item}
                    style={columns > 1 ? { width: `calc(100% / ${columns})` } : null}
                />
            ))}
        </ul>
    );
};

export default List;

List.propTypes = {
    // items that make up the list
    items: PropTypes.array.isRequired,

    // className to pass onto tags
    className: PropTypes.string,

    // function to handle list item selection
    onSelect: PropTypes.func,

    // optional button at the top of the list
    buttonText: PropTypes.string,
    handleButton: PropTypes.func,

    // number of columns for the list
    columns: PropTypes.number,

    // whether or not the button is disabled
    disabled: PropTypes.bool,
    // active elementside
    activeItem: PropTypes.string
};

List.defaultProps = {
    className: '',
    columns: 1,
    disabled: false
};
