import React, { Fragment, useContext } from 'react';
import { useLocation, Link, BrowserRouter, Route, Routes } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

import { WidthContext } from '../../../contexts/WidthContextProvider';
import { Heading, Subtitle } from '../../modules/Typography';
import Paper from '../../modules/Paper';

import ActiveLine from './ActiveLine';
import ActiveAreaAspectRatio from './ActiveAreaAspectRatio';
import ActiveArea from './ActiveArea';
import Pillarboxing from './Pillarboxing';
import UncompressedFrameSize from './UncompressedFrameSize';
import Luminance from './Luminance';

const nestedRoutes = {
    'Aspect Ratio Calculators': [
        { path: 'aspect/active-line', component: <ActiveLine />, name: 'Active Line' },
        { path: 'aspect/active-area-vs-aspect-ratio', component: <ActiveAreaAspectRatio />, name: 'Active Area vs Aspect Ratio' },
        { path: 'aspect/active-area', component: <ActiveArea />, name: 'Active Area' },
        { path: 'aspect/pillarboxing', component: <Pillarboxing />, name: 'Pillarboxing' }
    ],
    'Frame Size Calculators': [{ path: 'frame-size/uncompressed-frame-size', component: <UncompressedFrameSize />, name: 'Uncompressed Frame Size' }],
    'Luminance Calculator': [{ path: 'luminance/nits-foot-lamberts', component: <Luminance />, name: 'Nits and Foot-Lamberts' }]
};

const Calculators = props => {
    const url = useLocation().pathname;

    const selectedPath = url.replace('/calculators/', '');
    var selectedComponent = <ActiveLine />;
    Object.keys(nestedRoutes).map(key => {
        nestedRoutes[key].map((route, idx) => {
            if (selectedPath == route.path) {
                selectedComponent = route.component;
            }
        });
    });

    const { isMobile } = useContext(WidthContext);

    return (
        <main>
            <Helmet><title>Disney Media Tech Specs | Calculators</title></Helmet>
            <Heading number={5} heavy block className='calculators__title'>Calculators</Heading>

            <Paper className='calculators__section' elevation={6}>
                {!isMobile &&
                <aside className='calculators__aside'>
                    <nav>
                        {
                            Object.keys(nestedRoutes).map(key => {
                                return (
                                    <Fragment key={key}>
                                        <Subtitle number={3} className=''>{key}</Subtitle>
                                        <ul className='calculators__nav__list'>
                                            {
                                                nestedRoutes[key].map((route, idx) => {
                                                    return (
                                                        <li className='calculators__nav__list-item' key={idx}>
                                                            <Link className='Typography--subtitle1' to={`../../calculators/${route.path}`}>{route.name}</Link>
                                                        </li>
                                                    );
                                                })
                                            }
                                        </ul>
                                    </Fragment>
                                );
                            })
                        }
                    </nav>
                </aside>}

                <main style={{ width: '100%' }}>
                    <Routes>
                        <Route path='/' element={selectedComponent} exact />
                    </Routes>
                </main>
            </Paper>
        </main>
    );
};

export default Calculators;
