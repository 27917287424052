import { gql } from '@apollo/client';

export const GET_SUBCATEGORY = gql`
  query subcategories($subSlug: String, $catSlug: String) {
    subcategorieses(
      filters: { slug: { eq: $subSlug }, category: { slug: { eq: $catSlug } } }
    ) {
      data {
        id
        attributes {
          name
          documentContainer(pagination: { start: 0, limit: 1 }) {
            document {
              data {
                id
                attributes {
                  name
                  slug
                }
              }
            }
          }
        }
      }
    }
  }
`;
