import { gql } from '@apollo/client';

export const SEARCH = gql`
  query ($searchTerm: String) {
    documents(pagination: {start: 0, limit: 10},
              filters: { or: [
                { name: {containsi: $searchTerm } },
                { description: {in: [$searchTerm] } },
                { subcategories: { name: {containsi: $searchTerm } }}
              ]}
              ) {
      data {   
        id
        attributes {
          name
          slug
          description
          subcategories {
            data {
              id
              attributes {
                name
                slug
                description
                category {
                  data {
                    id
                    attributes {
                      name
                      slug
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
