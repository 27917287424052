import React from 'react';
import { Subtitle } from '../../modules/Typography';

const Tips = ({ tips }) => {
    return (
        <section className='calculator__tips'>
            <Subtitle number={2} className='calculator__tips__title'>Tips</Subtitle>
            <ul className='calculator__tips__list'>
                {
                    tips.map((tip, ind) => {
                        return <li key={ind}>{tip.tip}</li>;
                    })
                }
            </ul>
        </section>
    );
};

export default Tips;
