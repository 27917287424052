import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import { GET_PERMALINKS, GET_PERMALINK } from '../../queries';

import Paper from '../modules/Paper';
import { Subtitle, Heading } from '../modules/Typography';
import Loading from '../modules/Loading';

const Permalinks = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [permalinks, setPermalinks] = useState([]);
  const { uuid } = params;
  const baseUrl = window.location.origin;

  const {
    data: permalinksData,
    loading: permalinksLoading,
    error: permalinksError
  } = useQuery(GET_PERMALINKS);

  const { data: permalinkData } = useQuery(GET_PERMALINK, {
    variables: { uuid }
  });

  const validatePermalink = async (url) => {
    try {
      const response = await fetch(url, { method: 'HEAD' });
      if (response.ok) {
        window.location.replace(url);
      } else {
        window.location.replace('/');
      }
    } catch (error) {
      setError('Permalink Cannot be validated');
    }
  };

  useEffect(() => {
    if (
      window.location.pathname.startsWith('/permalink') &&
      uuid === undefined
    ) {
      navigate('/');
    }
  }, [uuid, navigate]);

  useEffect(() => {
    if (uuid && permalinkData) {
      if (permalinkData.permalinks.data.length === 0) {
        navigate('/');
      } else {
        validatePermalink(
          permalinkData.permalinks.data[0].attributes.targetUrl
        );
      }
    }
  }, [permalinkData]);

  useEffect(() => {
    if (!permalinksLoading) {
      setPermalinks(permalinksData.permalinks.data);
    }
  }, [permalinksLoading]);

  if (permalinksLoading || uuid) return <Loading />;
  if (permalinksError) console.error({ error });

  return (
    <div className='Permalinks'>
      <Heading number={5} heavy block className='Document__title'>
        Permalinks
      </Heading>
      <Paper elevation={6} className='Search__Paper'>
        <div className='flex Search__Header'>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%'
            }}
          >
            <Subtitle block number={3}>
              {permalinks.length === 0
                ? 'No permalinks available'
                : `Displaying ${permalinks.length} of ${permalinks.length} Permalinks availables`}
            </Subtitle>
            {permalinks.length > 0 && (
              <div style={{ display: 'flex', gap: '.5rem' }}>
                <button>Sort</button>
                <button>Filter</button>
                <button>Search</button>
              </div>
            )}
          </div>
        </div>
        <div className='cardsContainer'>
          {permalinks.length > 0 &&
            permalinks.map((permalink) => (
              <div className='card' key={permalink.uuid}>
                <div className='data'>
                  <div className='row'>
                    <span>Name:</span>
                    {permalink.attributes.name}
                  </div>
                  <div className='row'>
                    <span>Permalink:</span>
                    <Link
                      to={`/permalink/${permalink.attributes.uuid}`}
                      key={permalink.attributes.uudi}
                      target={
                        baseUrl !== 'http://localhost:3000' ? '_blank' : ''
                      }
                    >
                      {`${baseUrl}/permalink/${permalink.attributes.uuid}`}
                    </Link>
                  </div>
                  <div className='row'>
                    <span>Target URL:</span>
                    <Link
                      to={permalink.attributes.targetUrl}
                      key={permalink.attributes.uudi}
                      target='_blank'
                    >
                      {permalink.attributes.targetUrl}
                    </Link>
                  </div>
                </div>
                <div className='actions'>
                  <div className='status'>
                    Status:
                    {permalink.attributes.status === true ? (
                      <span className='online'>Online</span>
                    ) : (
                      <span className='offline'>Offline</span>
                    )}
                  </div>
                  <PermalinkButton
                    permalink={`${baseUrl}/permalink/${permalink.attributes.uuid}`}
                  />
                </div>
              </div>
            ))}
        </div>
      </Paper>
    </div>
  );
};

export default Permalinks;

const PermalinkButton = ({ permalink }) => {
  const [permalinkCopied, setPermalinkCopied] = useState(false);
  const copyPermalink = async () => {
    try {
      navigator.clipboard.writeText(permalink).then(() => {
        setPermalinkCopied(true);
        setTimeout(() => {
          setPermalinkCopied(false);
        }, 3000);
      });
    } catch (error) {
      console.error('Permalink cannot be copied!', error);
    }
  };
  return (
    <div className='button'>
      <button onClick={copyPermalink}>
        {permalinkCopied ? 'Permalink copied!' : 'Copy Permalink'}
      </button>
    </div>
  );
};
