import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { useQuery } from '@apollo/client';
import { Helmet } from 'react-helmet-async';
import { Heading, Body } from '../modules/Typography';
import Paper from '../modules/Paper';
import Loading from '../modules/Loading';
import Table from '../modules/Table';
import { GET_HOME } from '../../queries';
import parseMarkdown from '../../utils/markdownParser';
import Button from '../modules/Button';
import Icon from '../modules/Icon';

const Home = props => {
    const { data, loading } = useQuery(GET_HOME);
    const [alertOpen, setAlertOpen] = useState(true);

    if (loading) return <Loading />;

    const bannerUrl = data ? data.homePage.data.attributes.banner.data.attributes.url : '#';
    const glossary = data ? data.homePage.data.attributes.glossary : {};
    const homeCard = data ? data.homePage.data.attributes.homeCard : [];
    const { alertMessage, alertOn } = data ? data.homePage : {};

    return (
        <>
            <Helmet><title>Disney Media Tech Specs</title></Helmet>
            <div className='Document'>
                {alertOn && alertOpen &&
                    <div className='Document__alert'>
                        <div className='Document__alert__text'><Icon className='Document__alert__icon' name='warning' />
                            {parseMarkdown(alertMessage)}
                        </div>
                        <Icon name='close' className='Document__alert__close' onClick={() => setAlertOpen(false)} />
                    </div>}
                <Heading number={5} heavy block className='Document__title'>Welcome</Heading>
                <div>
                    <img src={bannerUrl} className='Document__image' />
                </div>
                <Heading number={2} className='Document__text__title'>Welcome</Heading>
                <Body className='Document__text__home' number={1}>
                    Welcome to the home of the Mastering Specifications for the Walt Disney Studios World Wide Post Production Operations
                </Body>
                {data?.homePage?.update?.length ? (
                    <div className='Document__Updates__home'>
                        <Paper>
                            <Heading number={5} heavy block className='Document__Updates__title'>Updates</Heading>
                            <Table
                                data={data.homePage.update.sort((a, b) => {
                                    if (moment(a.date).isBefore(moment(b.date))) {
                                        return -1;
                                    }
                                    return 1;
                                })
                                    .map(update => ([
                                        moment(update.date).format('L'),
                                        parseMarkdown(update.description),
                                        <Button key={Math.random()} type='secondary' color='primary-light'><Link to={`/${update.categorySlug.slug}/${update.subcategorySlug.slug}/${update.buttonLink.slug}`}>View Updates</Link></Button>
                                    ]))}
                                className='Document__Updates__home__table'
                            />
                        </Paper>
                    </div>
                ) : ''}
                <div className='Document__boxes'>
                    {(homeCard).map((item, idx) => {
                        return (
                            <div key={idx} className='box-width'>
                                <Link to={`/${item.category.data.attributes.slug}`}>
                                    <Paper className='Document__box home'>
                                        <div className='Document__box__content'>
                                            <Heading number={6} className='Document__box__title'>
                                                {item.category.data.attributes.name}
                                            </Heading>
                                            <Body>{parseMarkdown(item.description)}</Body>
                                        </div>
                                    </Paper>
                                </Link>
                            </div>
                        );
                    })}
                    <div className='box-width'>
                        <Link to='/glossary'>
                            <Paper className='Document__box'>
                                <div className='Document__box__content'>
                                    <Heading number={6} className='Document__box__title'>
                                        Glossary
                                    </Heading>
                                    <Body>{parseMarkdown(glossary.description)}</Body>
                                </div>
                            </Paper>
                        </Link>
                    </div>

                </div>
                {/* <div className='Document__contact'>
                    <p className=''>Do you have a question?</p>

                    <Button
                        text='Contact Us'
                        icon='email'
                        type='primary'
                        color='primary-light'
                        className='Document__contact__button'
                        link='/contact'
                    />
                </div> */}

            </div>
        </>
    );
};

Home.propTypes = {

};

export default Home;
