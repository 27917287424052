function _aspectRatio (width, height) {
    const aspectRatio = width / height;
    return Number(aspectRatio.toFixed(5)); // 5 decimals
}

const calculate = ({ width, height }) => {
    const aspectRatio = _aspectRatio(width, height);
    const aspectRatioRounded = aspectRatio.toFixed(2);

    return {
        aspectRatio, aspectRatioRounded
    };
};

export default calculate;
