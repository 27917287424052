import React from 'react';
import PropTypes from 'prop-types';
import Checkbox from './Checkbox';
import { Body } from './Typography';

const getDangerouslyHtmlProperty = (object, property) => {
    for (const key in object) {
        if (object.hasOwnProperty(key)) {
            if (key === property) {
                return object[key];
            } else if (typeof object[key] === 'object') {
                const recursionResult = getDangerouslyHtmlProperty(
                    object[key],
                    property
                );
                if (recursionResult !== undefined) {
                    return recursionResult;
                }
            }
        }
    }
};

const fillArray = (array) => {
    const maxLength = Math.max(...array.map((arr) => arr.length));
    const filledArray = array.map((arr) =>
        arr.length < maxLength
            ? [...arr, ...Array(maxLength - arr.length).fill('')]
            : arr
    );
    return filledArray;
};

const getColumnsContent = (data) => {
    const transposedData = data[0].map((_, i) => data.map((row) => row[i]));

    const totalColumns = transposedData.map((item) => {
        const cellMaxCharLength = item.reduce((max, element) => {
            if (typeof element === 'object') {
                return 60;
            }
            if (element === null) return 0;
            if (element === undefined) return 0;
            return Math.max(max, element.length);
        }, 0);
        return cellMaxCharLength;
    });
    if (totalColumns.length === 2) {
        return 'tbody-row-2-cols';
    } else if (totalColumns.length === 3) {
        return 'tbody-row-3-cols';
    } else if (totalColumns.length === 4) {
        return 'tbody-row-4-cols';
    } else if (totalColumns.length === 5) {
        return 'tbody-row-5-cols';
    } else {
        const totalContent = totalColumns.reduce((acc, long) => acc + long, 0);
        const cellsPercents = totalColumns.map((long) =>
            Math.round((long / totalContent) * 99)
        );
        return cellsPercents;
    }
};

const Table = (props) => {
    const colWidths = getColumnsContent(props.data);
    return (
        <>
            {props.columns &&
      props.columns.length &&
      props.columns.find((col) => col) &&
      props.title ? (
          <Body className='Table__title with-header' number={2} block heavy>
                        {props.title}
                    </Body>
                ) : (
                    ''
                )}
            <div
                className={`Table__wrapper ${props.className} ${
                    props.hasBorder ? 'Table__wrapper--border' : ''
                }`}
            >
                {!(
                    props.columns &&
          props.columns.length &&
          props.columns.find((col) => col)
                ) && props.title ? (
                    <Body className='Table__title' number={2} block heavy>
                            {props.title}
                        </Body>
                    ) : (
                        ''
                    )}
                <table className='Table'>
                    {props.columns &&
          props.columns.length &&
          props.columns.find((col) => col) ? (
              <thead>
                                <tr className='Table__header'>
                      {props.selectable && (
                                        <th className='Table__cell Typography--body2' />
                                    )}
                      {props.columns.map((header) => (
                                        <th
                              key={header || Math.random()}
                              className='Table__cell Typography--body2 Typography--heavy'
                          >
                              {header}
                          </th>
                                    ))}
                  </tr>
                            </thead>
                        ) : (
                            ''
                        )}

                    <tbody className='Table__body'>
                        {fillArray(props.data).map((row, index) => (
                            <tr key={index} className={`Table__row ${colWidths}`}>
                                {props.selectable && (
                                    <Checkbox
                                        checked={row[0].checked}
                                        value={row[0].value}
                                        handleCheck={props.handleCheck}
                                        className='Table__checkbox'
                                    />
                                )}
                                {(props.selectable ? row.slice(1) : row).map(
                                    (cell, cellIndex) => (
                                        <td
                                            key={`${cell}-${index}-${cellIndex}`}
                                            className='Table__cell Typography--body2'
                                        >
                                            <span>{cell}</span>
                                        </td>
                                    )
                                )}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            {props.footnote ? (
                <Body
                    className='Document__Requirements__Table__footer'
                    block
                    number={2}
                >
                    {props.footnote}
                </Body>
            ) : (
                ''
            )}
        </>
    );
};

export default Table;

Table.propTypes = {
    // className to pass down to tags
    className: PropTypes.string,
    title: PropTypes.string,
    footnote: PropTypes.string,

    // data to show in the table
    data: PropTypes.array,

    // table column names
    columns: PropTypes.arrayOf(PropTypes.string),

    // whether or not the table has a border around it
    hasBorder: PropTypes.bool,

    // are the rows selectable
    selectable: PropTypes.bool,
    handleCheck: PropTypes.func
};

Table.defaultProps = {
    selectable: false,
    className: '',
    hasBorder: true
};
