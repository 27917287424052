import { gql } from '@apollo/client';

export const GET_NAV = gql`
    query sideNav {
      sideNav {
        data {
          attributes {
            categoryContainer {
              categories {
                data {
                  id
                  attributes {
                    name
                    slug
                    subcategoryContainer {
                      subcategory {
                        data {
                          id  
                          attributes {
                            name
                            slug
                            documentContainer(pagination: {start: 0, limit: 15}) {
                              document {
                                data {
                                  id
                                  attributes {
                                    name
                                    slug
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
`;
export const GET_HOME = gql`
    query homePage {
      homePage {
        data {
          attributes {
            banner {
              data { attributes {
                url
              }}
            }
            alertOn
            alertMessage
            glossary {
              description
            }
            homeCard {
              id
              description
              category {
                data { attributes {
                  slug
                  name
                }}
              }
            }
            update {
                date
                category
                description
                categorySlug {
                  data { attributes {
                    slug
                  }}
                }
                subcategorySlug {
                  data { attributes {
                    slug
                  }}
                }
                buttonLink {
                  data { attributes {
                    slug
                  }}
                }
            }
          }
        }
      }
    }
`;

export const SEND_EMAIL = gql`
mutation sendRequest($email: String!, $subject: String!, $message: String!) {
  sendRequest (
  email: $email, 
  subject: $subject, 
  message: $message
  ) 
  {
    sent
  }
}
`;
