import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import Creatable from 'react-select/creatable';

import { GET_TIPS } from '../../../../queries';
import Loading from '../../../modules/Loading';
import Tips from '../Tips';
import { Caption, Heading, Subtitle } from '../../../modules/Typography';
import TextField from '../../../modules/TextField';
import Button from '../../../modules/Button';
import { useFocus } from '../../../../utils/hooks';
import xCircleIcon from '../../../../assets/icon_x_circle.svg';

import validator from './validator';
import calculate from './formulas';

const initialResults = {
    singleFrameSize: 0,
    totalSize: 0
};

const Luminance = props => {
    const { data, loading } = useQuery(GET_TIPS, { variables: { calculator: 'Luminance' } });

    const [amount, setAmount] = useState('');
    const [conversionType, setConversionType] = useState('');
    const [results, setResults] = useState(initialResults);
    const [errors, setErrors] = useState({});

    const [amountRef, setAmountFocus] = useFocus();

    if (loading) return <Loading />;

    const handleCalculation = e => {
        e.preventDefault();
        const _errors = {};
        setErrors(_errors);

        const params = {
            amount: amount.length === 0 ? '' : Number(amount),
            conversionType: (conversionType && conversionType.value.length !== 0) ? Number(conversionType.value) : ''
        };

        const isValid = validator(params);
        if (!isValid) {
            for (const error of validator.errors) {
                const { instancePath, message } = error;
                _errors[instancePath] = { message };
            }

            setErrors(_errors);
            return;
        }

        const results = calculate(params);
        setResults(results);
    };

    const handleReset = e => {
        e.preventDefault();
        setAmount('');
        setConversionType('');
        setErrors({});
        setResults(initialResults);
    };

    const conversionOptions = [
        { value: '0.291863508', label: 'Nits to Foot-Lamberts' },
        { value: '3.4262590996', label: 'Foot-Lamberts to Nits' }
    ];
    const colourStyles = {
        control: (styles) => ({
            ...styles,
            border: 0,
            boxShadow: 'unset',
            ':hover': {
                border: 0,
                boxShadow: 'unset'
            }
        })
    };

    const tips = data?.calculatorses.data[0]?.attributes.tips ?? [];
    return (
        <>
            <section className='calculator__wrapper'>

                <form className='calculator__form'>
                    <Heading number={5} heavy block className='calculator__form__title'>Nits and Foot-Lamberts Conversion</Heading>

                    <div className='calculator__input__wrapper'>
                        <TextField
                            className='calculator__input'
                            label='Amount'
                            type='text'
                            value={amount}
                            onChange={e => setAmount(e.target.value)}
                            error={Boolean(errors['/amount']) ?? false}
                            errorMessage={errors['/amount']?.message}
                            ref={amountRef}
                            required
                        />
                        <Caption className='calculator__input__helper-text'>Enter conversion amount</Caption>
                        {
                            amount.length !== 0 && Boolean(errors['/amount']) &&
                            <img
                                className='clear-input' alt='calculator icon'
                                src={xCircleIcon} width='14px' height='14px'
                                onClick={() => {
                                    setAmount('');
                                    setAmountFocus();
                                }}
                            />
                        }
                    </div>
                    <div className='calculator__input__wrapper'>
                        <div className='TextField calculator__input TextField--padded'>
                            <label className='TextField__label calculator__input__label'>
                                <span className='Typography Typography--caption1 Typography--color-default'>Conversion Type</span>
                            </label>

                            <div className='TextField__style-wrapper TextField__style-wrapper--default selectable'>
                                <Creatable
                                    className='TextField__input'
                                    styles={colourStyles}
                                    isClearable
                                    value={conversionType}
                                    onChange={opt => setConversionType(opt)}
                                    options={conversionOptions}
                                />
                            </div>
                        </div>
                        <Caption className='calculator__input__helper-text'>select nits or foot-Lamberts</Caption>
                        {
                            Boolean(errors['/converstionType']) ?? false
                                ? <span className='Typography Typography--caption2 Typography--color-default TextField__error Typography--error'>
                                    {errors['/conversionType']?.message}
                                  </span>
                                : null
                        }
                    </div>
                    <div className='calculator__form__controls'>
                        <Button
                            className='calculator__form__control-calculate'
                            type='primary'
                            color='primary-light'
                            text='Calculate'
                            onClick={(e) => handleCalculation(e)}
                        />
                        <Button
                            className='calculator__form__control-reset'
                            type='secondary'
                            color='primary-light'
                            text='Reset'
                            onClick={(e) => handleReset(e)}
                        />
                    </div>
                </form>

                <aside className='calculator__results'>
                    <Heading number={5} heavy block className='calculator__results__title'>Results</Heading>

                    <ul>
                        <li className='calculator__result-item'>
                            <Subtitle>Total</Subtitle>
                            <Subtitle>{results.conversionResults}</Subtitle>
                        </li>
                        <li className='calculator__result-item'>
                            <Subtitle>Total (Rounded)</Subtitle>
                            <Subtitle>{results.roundedResults}</Subtitle>
                        </li>
                    </ul>

                </aside>
            </section>

            {
                !tips || tips.length === 0
                    ? null
                    : <Tips tips={tips} />
            }
        </>
    );
};

export default Luminance;
