import React from 'react';
import CategoriesContextProvider from '../contexts/CategoriesContextProvider';
import WidthContextProvider from '../contexts/WidthContextProvider';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import '../styles/main.scss';

// import GraphiqlEditor from './GraphiqlEditor';
import Glossary from './pages/Glossary';
import Layout from './layout/Layout';
import Document from './pages/Document';
import SpecPreview from './pages/SpecPreview';
import Category from './pages/Category';
import Home from './pages/Home';
import SearchResults from './pages/SearchResults';
import Contact from './pages/Contact';
import Calculators from './pages/Calculators';
import Permalinks from './pages/Permalinks';

function App () {
  return (
    <WidthContextProvider>
      <CategoriesContextProvider>
        <BrowserRouter>
          <Layout>
            <Routes>
              <Route path='/' element={<Home />} />
              <Route
                path='/84c59ec8ceee62b82c0793e47333e8ae'
                element={<Permalinks />}
              />
              <Route path='/permalink/:uuid?' element={<Permalinks />} />
              <Route path='/glossary' element={<Glossary />} />
              <Route path='/calculators' element={<Calculators />} />
              <Route
                path='/calculators/aspect/active-line'
                element={<Calculators />}
              />
              <Route
                path='/calculators/aspect/active-area-vs-aspect-ratio'
                element={<Calculators />}
              />
              <Route
                path='/calculators/aspect/active-area'
                element={<Calculators />}
              />
              <Route
                path='/calculators/aspect/pillarboxing'
                element={<Calculators />}
              />
              <Route
                path='/calculators/luminance/nits-foot-lamberts'
                element={<Calculators />}
              />
              <Route
                path='/calculators/frame-size/uncompressed-frame-size'
                element={<Calculators />}
              />
              <Route path='/contact' element={<Contact />} />
              <Route
                path='/search-results/:query'
                element={<SearchResults />}
              />
              <Route path='/preview/spec/:slug' element={<SpecPreview />} />
              <Route path='/:catSlug' element={<Category />} />
              <Route path='/:catSlug/:subSlug/:slug' element={<Document />} />
              <Route path='*' element={<Home />} />
            </Routes>
          </Layout>
        </BrowserRouter>
      </CategoriesContextProvider>
    </WidthContextProvider>
  );
}

// const routes = [
//     { path: '/', component: Home },
//     { path: '/glossary', component: Glossary },
//     { path: '/calculators', component: Calculators },
//     { path: '/contact', component: Contact },
//     { path: '/search-results/:query', component: SearchResults },
//     { path: '/preview/spec/:slug', component: SpecPreview },
//     { path: '/:catSlug', component: Category },
//     { path: '/:catSlug/:subSlug/:slug', component: Document }
// ];

// function App () {
//     return (
//         <Router>
//             <WidthContextProvider>
//                 <CategoriesContextProvider>
//                     <Layout>
//                         <Switch>
//                             <Route path='/graphiql' component={GraphiqlEditor} />
//                             {routes.map((route, key) => {
//                                 const {
//                                     path,
//                                     component
//                                 } = route;

//                                 if (path === '/calculators') { return <Route path={path} component={component} key={key} />; }

//                                 return <Route path={path} component={component} exact key={key} />;
//                             })}
//                             <Redirect to='/' />
//                         </Switch>
//                     </Layout>
//                 </CategoriesContextProvider>
//             </WidthContextProvider>
//         </Router>
//     );
// }

export default App;
